import React, { useState, useEffect } from 'react';
import { BrainCircuit, Plus } from 'lucide-react';
import { PersonaWithCognition } from '../../types/cognitive';

interface MemoryIndicatorProps {
  persona: PersonaWithCognition;
  onOpenMemories: () => void;
  newMemoryId?: string;
}

export const MemoryIndicator: React.FC<MemoryIndicatorProps> = ({
  persona,
  onOpenMemories,
  newMemoryId
}) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [showBadge, setShowBadge] = useState(false);

  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);

  useEffect(() => {
    if (newMemoryId) {
      setIsAnimating(true);
      setShowBadge(true);
      
      // Stop wiggle animation after 1 second
      const wiggleTimer = setTimeout(() => {
        setIsAnimating(false);
      }, 1000);

      return () => clearTimeout(wiggleTimer);
    }
  }, [newMemoryId]);

  const handleClick = () => {
    setShowBadge(false);  // Clear the badge when clicked
    onOpenMemories();     // Open memories modal
  };

  return (
    <div
      className="relative group mt-1"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
    <button
      onClick={handleClick}
      className={`relative transition-all ${
        isHovering ? 'wiggle-hover tilt-right' : ''
      }`}
      aria-label="View memories"
    >
      <BrainCircuit 
        className={`w-5 h-5 text-zinc-400 group-hover:text-zinc-300 transition-colors
                   ${isAnimating ? 'animate-wiggle' : ''}`}
      />
      
      {/* New memory badge */}
      {showBadge && (
        <Plus className="absolute -top-1 -right-1 w-3 h-3 bg-blue-500 rounded-full 
                     animate-fade-in cursor-pointer" />
      )}

    </button>
      {/* Count badge */}
      {persona.memories && persona.memories.length > 0 && !showBadge && (
        <div className="absolute -top-2 -right-2 bg-blue-500 text-white text-xs 
        rounded-full w-4 h-4 flex items-center justify-center">
          {persona.memories?.length}
        </div>
      )}
    </div>
  );
};