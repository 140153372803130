import React, { useState } from 'react';
import { Copy, Check } from 'lucide-react';

const FloatingCopyButton = ({ text = "", className = "" }) => {
  const [copied, setCopied] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  return (
    <div 
      className={`
        absolute right-0 top-0 translate-x-4
        opacity-0 group-hover:opacity-100
        transition-all duration-300 ease-in-out
        ${className}
      `}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      <button
        onClick={handleCopy}
        className="p-1.5 bg-zinc-700/50 rounded-md hover:bg-zinc-700 transition-colors group"
      >
        {copied ? (
          <div className="flex items-center gap-1">
            <Check size={14} className="text-green-400" />
          </div>
        ) : (
          <div className="flex items-center gap-1">
            <Copy size={14} className="text-zinc-400 group-hover:text-zinc-300" />
          </div>
        )}
      </button>
    </div>
  );
};

export default FloatingCopyButton;