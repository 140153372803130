// StorageIndicator.tsx
import React, { useState } from 'react';
import { HardDrive } from 'lucide-react';

interface StorageIndicatorProps {
  onClick: () => void;
}

const StorageIndicator: React.FC<StorageIndicatorProps> = ({ onClick }) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);

  return (
    <button
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`text-zinc-400 hover:text-white transition-all duration-300 ${
        isHovering ? 'wiggle-hover tilt-right' : ''
      }`}
      aria-label="Hard Storage"
    >
      <HardDrive size={20}className='mt-0.5' />
    </button>
  );
};

export default StorageIndicator;
