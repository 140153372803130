import React, { useState, useEffect, useCallback } from 'react';
import { Settings2, FolderOpen, HardDrive } from 'lucide-react';
import ConfigDrawer from '../ui/ConfigDrawer';
import { MemoryIndicator } from '../memories/MemoryIndicator';
import { MemoryManagementDrawer } from '../ui/MemoryManagementDrawer';
import { usePersona } from '../../hooks/usePersona';
import { projectStore } from '../../services/projectStore';
import { personaStore } from '../../services/personaStore';
import CognitiveModal from '../memories/CognitiveModal';
import { ProjectDrawer } from '../projects/ProjectDrawer';
import { apiKeyService } from '../../services/ApiKeyService';
import StorageIndicator from '../ui/StorageIndicator';
import ConfigIndicator from '../ui/ConfigIndicator';
import ProjectIndicator from '../projects/ProjectIndicator';
import PersonaNameButton from '../ui/PersonaNameButton';

// Common header interface to share between both components
export const ChatInputHeader: React.FC = () => {
  const [isConfigOpen, setIsConfigOpen] = useState(false);
  const [isMemoryOpen, setIsMemoryOpen] = useState(false);
  const [isMemoriesModalOpen, setIsMemoriesModalOpen] = useState(false);
  const [showProjectInfo, setShowProjectInfo] = useState(false);
  const [newMemoryId, setNewMemoryId] = useState<string>();
  const [isProjectDrawerOpen, setIsProjectDrawerOpen] = useState(false);
  const persona = usePersona();
  const [activeProjects, setActiveProjects] = useState<{ id: string; name: string; }[]>([]);

  // Define loadActiveProjects
  const loadActiveProjects = useCallback(() => {
    const projects = projectStore.getAllProjects()
      .filter(p => p.isActive)
      .map(({ id, name }) => ({ id, name }));
    setActiveProjects(projects);
  }, []);

  useEffect(() => {
    // Add memory event listener
    const handleMemoryCreated = (event: CustomEvent<{ memoryId: string }>) => {
      setNewMemoryId(event.detail.memoryId);
      // Clear notification after delay
      setTimeout(() => {
        setNewMemoryId(undefined);
      }, 3000);
    };

    loadActiveProjects(); // Initial load
    
    window.addEventListener('memory-created', handleMemoryCreated as EventListener);
    window.addEventListener('project-changed', loadActiveProjects);
    window.addEventListener('project-updated', loadActiveProjects);

    return () => {
      window.removeEventListener('memory-created', handleMemoryCreated as EventListener);
      window.removeEventListener('project-changed', loadActiveProjects);
      window.removeEventListener('project-updated', loadActiveProjects);
    };
  }, [loadActiveProjects]);

  const handleAddMemory = (content: string) => {
    const memoryId = Date.now().toString();
    const createdAt = new Date();
    personaStore.addMemory(persona.id, {
      content,
      createdAt,
      source: 'manual'
    });
    setNewMemoryId(memoryId);
  };

  const handleDeleteMemory = (memoryId: string) => {
    personaStore.removeMemory(persona.id, memoryId);
  };

  const handleCognitiveUpdate = (config: any) => {
    personaStore.updateCognitiveConfig(persona.id, config);
  };

  const handleApiKey = async () => {
    await apiKeyService.hasStoredKey()
  }


  return (
    <>
      <div className="border-b border-zinc-800 bg-zinc-900/50 backdrop-blur sticky top-0 z-10">
        <div className="relative w-full">
          {/* System Icons - Absolute Positioned */}
          <div className="absolute left-4 top-1/2 -translate-y-1/2 flex items-center gap-7 z-20">
            <ConfigIndicator onClick={() => setIsConfigOpen(true)} />
            <StorageIndicator onClick={() => setIsMemoryOpen(true)}/>
          </div>

          <div className="max-w-[1600px] mx-auto w-full px-4 md:px-8 py-4 flex items-center justify-center">
            {/* Center - Dynamic Title & RAG Status */}
            <div className="flex items-center gap-7">
              <MemoryIndicator
                persona={persona}
                onOpenMemories={() => setIsMemoriesModalOpen(true)}
                newMemoryId={newMemoryId}
              />
              {/* Use PersonaNameButton here */}
              <PersonaNameButton name={persona.name} onClick={() => setIsConfigOpen(true)} />

              {/* Project Warning */}
              {activeProjects.length === 0 && (
                <div className="relative">
                <div className="flex items-center gap-2 text-zinc-400 hover:text-white transition-colors text-sm bg-zinc-800/50 px-2 py-1 rounded">
                  <FolderOpen size={14} />
                    <span>No active projects</span>
                </div>
                </div>
              )}
              {activeProjects.length > 0 && (
                <div className="relative">
                  <button
                    onClick={() => setShowProjectInfo(!showProjectInfo)}
                    className="flex items-center gap-2 text-zinc-400 hover:text-white transition-colors text-sm bg-zinc-800/50 px-2 py-1 rounded"
                  >
                    <FolderOpen size={14} />
                    <span>{activeProjects.length} active project{activeProjects.length !== 1 ? 's' : ''}</span>
                  </button>

                  {showProjectInfo && (
                    <div className="absolute top-full left-1/2 -translate-x-1/2 mt-2 w-64 bg-zinc-800 border border-zinc-700 rounded-lg p-3 shadow-lg z-30">
                      <div className="text-sm text-zinc-300 mb-2">
                        Active projects used for context:
                      </div>
                      <div className="space-y-1">
                        {activeProjects.map(project => (
                          <div key={project.id} className="text-sm text-zinc-400 flex items-center gap-2">
                            <FolderOpen size={12} />
                            <span>{project.name}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <ProjectIndicator onClick={() => setIsProjectDrawerOpen(true)} />
        </div>
      </div>

      {/* Drawers and Modals */}
      <ConfigDrawer 
        isOpen={isConfigOpen}
        onClose={() => setIsConfigOpen(false)}
      />
      <MemoryManagementDrawer
        isOpen={isMemoryOpen}
        onClose={() => setIsMemoryOpen(false)}
      />
      <ProjectDrawer
        isOpen={isProjectDrawerOpen}
        onClose={() => setIsProjectDrawerOpen(false)}
        onOpen={() => setIsProjectDrawerOpen(true)}
      />
      <CognitiveModal
        isOpen={isMemoriesModalOpen}
        onClose={() => setIsMemoriesModalOpen(false)}
        persona={persona}
        onAddMemory={handleAddMemory}
        onDeleteMemory={handleDeleteMemory}
        onUpdateConfig={handleCognitiveUpdate}
        onEditMemory={(memoryId, newContent) => {
          personaStore.editMemory(persona.id, memoryId, newContent);
        }}
      />
    </>
  );
};