// hooks/useChat.ts
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Message } from '../types/types';
import { chatStore } from '../utils/chatStore';
import { enhancedChatService } from '../services/enhancedChatService';
import { Model } from '../components/chat/ModelSelector';
import { useRAGContext } from './useRAGContext';
import { personaStore } from '../services/personaStore';
import { relicStore } from '../services/relicStore';

// Helper to create a persistent blob URL from a File
const createPersistentBlobUrl = async (file: File): Promise<string> => {
  const arrayBuffer = await file.arrayBuffer();
  const blob = new Blob([arrayBuffer], { type: file.type });
  return URL.createObjectURL(blob);
};

// Add new state for memory notifications
interface UseChatState {
  messages: Message[]; 
  isLoading: boolean;
  error: string | null;
  streamingContent: string;
  currentChatId: string | null;
  newMemoryId: string | null;
  isStreaming: boolean;
}

export const useChat = (chatId?: string) => {
  const [state, setState] = useState<UseChatState>({
    messages: [],
    isLoading: false,
    error: null,
    streamingContent: '',
    currentChatId: null,
    newMemoryId: null,
    isStreaming: false
  });
  
  const navigate = useNavigate();
  const { getRelevantContext } = useRAGContext();

  useEffect(() => {
    if (chatId) {
      const existingChat = chatStore.getChat(chatId);
      if (existingChat) {
        setState(prev => ({
          ...prev,
          messages: existingChat.messages,
          currentChatId: chatId
        }));
      } else {
        navigate('/');
      }
    }
  }, [chatId, navigate]);

  // Clear memory notification after delay
  useEffect(() => {
    if (state.newMemoryId) {
      const timer = setTimeout(() => {
        setState(prev => ({ ...prev, newMemoryId: null }));
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [state.newMemoryId]);

  const handleAssistantResponse = async (
    chatId: string, 
    currentMessages: Message[], 
    content: string,
    model: Model,
    images?: File[]
  ) => {
    try {
      const currentPersona = personaStore.getSelectedPersona();
      
      setState(prev => ({ 
        ...prev, 
        isLoading: true, 
        streamingContent: '', 
        error: null,
        isStreaming: true
      }));
  
      const ragContext = await getRelevantContext(content);
  
      // Create a temporary streaming message with persona
      const streamingMessage: Message = {
        id: 'streaming',
        content: '',
        role: 'assistant',
        timestamp: new Date(),
        model: {
          name: model.name,
          id: model.id
        },
        persona: {
          id: currentPersona.id,
          name: currentPersona.name
        }
      };

      // Keep track of accumulated content
      let accumulatedContent = '';
  
      setState(prev => ({
        ...prev,
        messages: [...currentMessages, streamingMessage]
      }));
  
      const assistantMessage = await enhancedChatService.streamMessage({
        content,
        messageHistory: currentMessages,
        onChunk: (chunk) => {
          accumulatedContent += chunk;
          setState(prev => ({
            ...prev,
            messages: prev.messages.map(msg => 
              msg.id === 'streaming' 
                ? { ...msg, content: accumulatedContent }
                : msg
            )
          }));
        },
        model,
        ragContext,
        images,
        chatId
      });
  
      // Replace streaming message with final message
      setState(prev => ({
        ...prev,
        messages: prev.messages.map(msg => 
          msg.id === 'streaming' ? {
            ...assistantMessage,
            persona: {
              id: currentPersona.id,
              name: currentPersona.name
            }
          } : msg
        ),
        isLoading: false,
        isStreaming: false
      }));
  
      // Update chat store with final messages
      const finalMessages = currentMessages.concat({
        ...assistantMessage,
        persona: {
          id: currentPersona.id,
          name: currentPersona.name
        }
      });
      
      chatStore.updateChat(chatId, finalMessages);
      
    } catch (err) {
      console.error('Error in handleAssistantResponse:', err);
      setState(prev => ({
        ...prev,
        error: err instanceof Error ? err.message : 'An error occurred',
        isLoading: false,
        isStreaming: false
      }));
    }
  };

  const sendMessage = async (content: string, model: Model, images?: File[]) => {
    try {
      setState(prev => ({ ...prev, error: null }));
      
      const userMessage: Message = {
        id: Date.now().toString(),
        content,
        role: 'user',
        timestamp: new Date(),
        attachments: images ? await Promise.all(images.map(async file => ({
          id: Math.random().toString(),
          type: 'image' as const,
          url: await createPersistentBlobUrl(file),
          name: file.name,
        }))) : undefined,
      };

      const newMessages = [...state.messages, userMessage];
      
      setState(prev => ({ ...prev, messages: newMessages }));

      if (!state.currentChatId) {
        const newChatId = chatStore.createChat(userMessage);
        setState(prev => ({ ...prev, currentChatId: newChatId }));
        window.history.replaceState(null, '', `/chat/${newChatId}`);
        await handleAssistantResponse(newChatId, newMessages, content, model, images);
      } else {
        chatStore.updateChat(state.currentChatId, newMessages);
        await handleAssistantResponse(state.currentChatId, newMessages, content, model, images);
      }
    } catch (err) {
      console.error('Detailed error:', err);
      setState(prev => ({ 
        ...prev, 
        error: err instanceof Error ? err.message : 'An error occurred while sending the message'
      }));
    }
  };

  const deleteChat = () => {
    if (state.currentChatId) {
      try {
        // 1. Clean up image blob URLs first
        state.messages.forEach(message => {
          message.attachments?.forEach(attachment => {
            if (attachment.type === 'image') {
              URL.revokeObjectURL(attachment.url);
            }
          });
        });

        // 2. Get all relics for this chat
        const chatRelics = relicStore.getRelicsForChat(state.currentChatId);

        // 3. Delete each relic
        chatRelics.forEach(relic => {
          relicStore.deleteRelic(relic.metadata.id);
        });

        // 4. Delete the chat from storage
        chatStore.deleteChat(state.currentChatId);

        // 5. Navigate back to home
        navigate('/');

      } catch (error) {
        console.error('Error during chat deletion:', error);
        setState(prev => ({
          ...prev,
          error: 'Failed to delete chat and associated relics'
        }));
      }
    }
  };

  // Clean up blob URLs when component unmounts
  useEffect(() => {
    return () => {
      state.messages.forEach(message => {
        message.attachments?.forEach(attachment => {
          if (attachment.type === 'image') {
            URL.revokeObjectURL(attachment.url);
          }
        });
      });
    };
  });

  return {
    ...state,
    sendMessage,
    deleteChat
  };
};