import { relicStore } from "./relicStore";
import { Relic, RelicMetadata, RelicType } from '../types/relic';
import { Model } from '../components/chat/ModelSelector';
import { personaStore } from "./personaStore";
import { apiKeyService } from "./ApiKeyService";
import { Project } from "../types/project";
import { ContentBlock, CacheableContentBlock } from "../types/types";
import { projectStore } from "./projectStore";


class EnhancedRelicService {

  private async prepareSystemBlocks(
    basePrompt: string,
    activeProjects: Project[],
  ): Promise<(ContentBlock | CacheableContentBlock)[]> {
    const systemBlocks: (ContentBlock | CacheableContentBlock)[] = [{
      type: "text",
      text: basePrompt
    }];
  
    // Get projects using cache
    const cachingProjects = activeProjects.filter(p => 
      p.contextConfig.usePromptCache && 
      (p.contextConfig.strategy === 'cache-only' || p.contextConfig.strategy === 'hybrid')
    );
  
    if (cachingProjects.length > 0) {
      const projectContentPromises = cachingProjects.map(async project => {
        const fileContents = await Promise.all(
          project.documents.map(async doc => {
            try {
              const content = await window.fs.readFile(doc.name, { encoding: 'utf8' });
              return `File: ${doc.name}\n${content}`;
            } catch (error) {
              console.warn(`Failed to read ${doc.name}:`, error);
              return '';
            }
          })
        );
  
        const validContents = fileContents.filter(content => content.length > 0);
        return `Project "${project.name}" Contents:\n${validContents.join('\n\n')}`;
      });
  
      const projectContents = await Promise.all(projectContentPromises);
      const validContents = projectContents.filter(content => content.length > 0);
  
      if (validContents.length > 0) {
        const cacheableBlock: CacheableContentBlock = {
          type: "text",
          text: validContents.join('\n\n==========\n\n'),
          cache_control: { type: "ephemeral" }
        };
        systemBlocks.push(cacheableBlock);
      }
    }
  
    return systemBlocks;
  }
  
  private readonly baseUrl = process.env.REACT_APP_API_URL || 
  (window.location.hostname === 'localhost' 
    ? 'http://localhost:8080' 
    : 'allain-express-server-production.up.railway.app');
    private decoder: TextDecoder;
  
    constructor() {
      this.decoder = new TextDecoder();
    }
  
    // Improved signal extraction with better error handling
    public extractRelicSignal(content: string): { text: string; shouldGenerateRelic: boolean } {
        const relicRegex = /<relic>(true|false)<\/relic>\s*$/i;
        const match = relicRegex.exec(content);
    
        if (!match) {
          return { text: content, shouldGenerateRelic: false };
        }
    
        const shouldGenerateRelic = match[1].toLowerCase() === 'true';
        
        // Clean the content
        const cleanContent = content.replace(relicRegex, '').trim();
        return { text: cleanContent, shouldGenerateRelic };
      }
  
    // Enhanced relic generation with proper event sequence
    public async generateRelic(options: {
        messageContent: string;
        chatId: string;
        messageId: string;
        model: Model;
      }): Promise<Relic> {
        try {
          // 1. Signal generation start
          window.dispatchEvent(new CustomEvent('relic-generation-start', {
            detail: { messageId: options.messageId }
          }));
    
          // 2. Generate metadata
          const metadata = await this.generateMetadata(options);
          
          // 3. Signal metadata available
          window.dispatchEvent(new CustomEvent('relic-metadata-update', {
            detail: { messageId: options.messageId, metadata }
          }));
    
          // 4. Generate content
          const content = await this.generateContent(options, metadata);
    
          // 5. Create final relic
          const relic: Relic = { metadata, content };
    
          // 6. Store relic
          relicStore.addRelic(relic);
    
          // 7. Signal completion
          window.dispatchEvent(new CustomEvent('relic-generation-complete', {
            detail: { messageId: options.messageId, relic }
          }));
    
          return relic;
        } catch (error) {
          console.error('Relic generation error:', error);
          window.dispatchEvent(new CustomEvent('relic-generation-error', {
            detail: { 
              messageId: options.messageId,
              error: error instanceof Error ? error.message : 'Unknown error'
            }
          }));
          throw error;
        }
      }

      private async getApiKey(): Promise<string> {
        try {
          const apiKey = await apiKeyService.getApiKey();
    
          if (!apiKey || typeof apiKey !== 'string' || !apiKey.startsWith('sk-')) {
            throw new Error('Invalid API key format');
          }
    
          return apiKey.trim();
        } catch (error) {
          console.error('Error getting API key in chat service:', error);
          throw new Error('API key not found or invalid. Please add your Anthropic API key in settings.');
        }
      }
  
      private async generateMetadata(options: {
        messageContent: string;
        chatId: string;
        messageId: string;
        model: Model;
      }): Promise<RelicMetadata> {
        
        const apiKey = await this.getApiKey();

        try {
          const requestPayload = {
            model: "claude-3-5-sonnet-latest",
            max_tokens: 1000,
            temperature: 0.1,
            messages: [{
              role: "user",
              content: `Analyze this content to identify the main output type. STRICTLY focus on the ACTUAL content to be generated, not examples or explanations:

              Key Rules:
              1. If ANY code needs to be written or a program needs to be created, ALWAYS classify as "code"
              2. If seeing code examples, those indicate we should generate code, not documentation
              3. Actual implementation/code generation requests should be "code", not "markdown"
              4. "markdown" is ONLY for pure documentation/text with NO implementation needed
              5. Identify the primary programming language requested or implied

              Required JSON Output:
              {
                "title": "[Descriptive name of what needs to be created]",
                "type": "[code|markdown|html|svg|react|mermaid]",
                "language": "[programming language if type=code]"
              }
          
          Content to analyze:
          ${options.messageContent}`
            }],
            system: `You are an expert at detecting content types and programming languages. You should:
          - Strongly prefer classifying as "code" when any programming language is involved
          - Only use "markdown" for pure documentation/text content or when explicitly requested
          - Look for language keywords, syntax patterns, and explicit mentions of programming languages
          - Be precise with language detection for code content
          - Never include explanations - respond only with the JSON object`
          };
      
          const response = await fetch(`${this.baseUrl}/api/chat`, {
            method: 'POST',
            headers: {
              'X-Api-Key': apiKey,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestPayload)
          });
      
          if (!response.ok) {
            throw new Error(`Metadata generation failed: ${response.statusText}`);
          }
      
          // Handle streaming response
          const reader = response.body?.getReader();
          if (!reader) {
            throw new Error('No reader available');
          }
      
          let fullResponse = '';
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            
            const chunk = this.decoder.decode(value, { stream: true });
            const lines = chunk.split('\n');
            
            for (const line of lines) {
              if (line.startsWith('data: ')) {
                try {
                  const data = JSON.parse(line.slice(6));
                  if (data.type === 'content_block_delta' && 
                      data.delta?.type === 'text_delta' && 
                      data.delta.text) {
                    fullResponse += data.delta.text;
                  }
                } catch (e) {
                  // Ignore parse errors for incomplete chunks
                }
              }
            }
          }
      
          // Extract JSON from the response
          const jsonMatch = fullResponse.match(/\{[\s\S]*\}/);
          if (!jsonMatch) {
            throw new Error('No valid JSON found in response');
          }
      
          const parsedData = JSON.parse(jsonMatch[0]);
      
          // Validate the parsed data
          if (!parsedData.title || !parsedData.type) {
            throw new Error('Invalid metadata format');
          }
      
          const metadata: RelicMetadata = {
            id: options.messageId,
            title: parsedData.title,
            type: this.validateRelicType(parsedData.type),
            language: parsedData.type === 'code' ? parsedData.language : undefined,
            timestamp: new Date(),
            chatId: options.chatId
          };
      
          return metadata;
      
        } catch (error) {
          console.error('Failed to parse metadata response, using default:', error);
          
          // Return default metadata if parsing fails
          return {
            id: options.messageId,
            title: 'Generated Content',
            type: 'code',
            language: 'typescript',
            timestamp: new Date(),
            chatId: options.chatId
          };
        }
      }
    
      private async generateContent(
        options: { messageContent: string; model: Model },
        metadata: RelicMetadata
      ): Promise<string> {
        const persona = personaStore.getSelectedPersona();

        // Get system blocks with caching
      const activeProjects = projectStore.getAllProjects()
        .filter(project => project.isActive);
      const systemBlocks = await this.prepareSystemBlocks(
        this.getRelicBasePrompt(),
        activeProjects
      );

        const requestPayload = {
          model: "claude-3-5-sonnet-latest",
          max_tokens: persona.maxTokens,
          temperature: persona.temperature,
          stream: true,
          messages: [{
            role: "user",
            content: [{
              type: "text",
              text: `Generate ${metadata.type}${metadata.language ? ` in ${metadata.language}` : ''} based on this content: ${options.messageContent}`
            }]
          }],
          system: systemBlocks
        };

        const apiKey = await this.getApiKey();

        // Add headers based on caching status
        const headers: Record<string, string> = {
          'X-Api-Key': apiKey,
          'Content-Type': 'application/json',
          'anthropic-version': '2023-06-01'
        };

        // Add caching header if any project uses caching
        if (activeProjects.some(p => p.contextConfig.usePromptCache)) {
          headers['anthropic-beta'] = 'prompt-caching-2024-07-31';
        }
    
        const response = await fetch(`${this.baseUrl}/api/chat`, {
          method: 'POST',
          headers,
          body: JSON.stringify(requestPayload)
        });
    
        if (!response.ok) {
          throw new Error(`Content generation failed: ${response.statusText}`);
        }
    
        if (!response.body) {
          throw new Error('No response body available');
        }
    
        const reader = response.body.getReader();
        let content = '';
    
        try {
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;
    
            const chunk = this.decoder.decode(value, { stream: true });
            
            const lines = chunk.split('\n');
    
            for (const line of lines) {
              if (line.startsWith('data: ')) {
                try {
                  const data = JSON.parse(line.slice(6));
                  if (data.type === 'content_block_delta' && 
                      data.delta?.type === 'text_delta' && 
                      data.delta.text) {
                    content += data.delta.text;
                    window.dispatchEvent(new CustomEvent('relic-content-update', {
                      detail: { messageId: metadata.id, content }
                    }));
                  }
                } catch (e) {
                  // Ignore JSON parse errors for incomplete chunks
                }
              }
            }
          }

          console.log(content);
        } finally {
          reader.releaseLock();
        }
    
        return content.trim();
      }

      // Get relic prompt with proper caching
      private getRelicBasePrompt(): string {
        return `You are a specialized component of an AI system focused on generating high-quality artifacts called relics.
          
      Your task is to generate properly formatted content for React Markdown. Follow these key rules:
      
      1. ALWAYS wrap your response in the appropriate markdown code fence based on type:
      
      CODE:
      \`\`\`[language]
      your code here
      \`\`\`
      
      HTML:
      \`\`\`html
      <!DOCTYPE html>
      your html here
      </html>
      \`\`\`
      
      SVG:
      \`\`\`svg
      your svg here
      \`\`\`
      
      REACT:
      \`\`\`tsx
      your react code here
      \`\`\`
      
      MERMAID:
      \`\`\`mermaid
      your diagram here
      \`\`\`
      
      2. Content Rules:
         - Complete, self-contained implementations
         - Include all necessary imports/dependencies
         - Follow language/format best practices
         - Proper indentation and formatting
         - Essential comments only
      
      3. Critical Format Rules:
         - ALWAYS include the code fences
         - Use the correct language identifier
         - No explanations or text outside the code fences
         - No duplicate or nested code fences
         - Clean, properly indented content
      
      Remember: Your entire response should be a properly formatted code block ready for React Markdown to render.`;
      }
    
    private validateRelicType(type: string): RelicType {
      const validTypes: RelicType[] = ['code', 'markdown', 'html', 'svg', 'react', 'mermaid'];
      const normalizedType = type?.toLowerCase() as RelicType;
      return validTypes.includes(normalizedType) ? normalizedType : 'code';
    }

    public getRelicSystemPrompt(): string {
      return `You are an expert at analyzing requests and determining when to generate high-quality code and content artifacts (relics).
    Your job is to:
    1. Determine if a request needs a relic
    2. Provide a clear, concise response about what will be built
    3. Signal your decision at the end
    
    Response Guidelines:
    - For relic-worthy requests:
      • Briefly describe what you'll build
      • Mention key features/components
      • Keep code examples minimal (<5 lines)
      • End with <relic>true</relic>
    
    - For non-relic requests:
      • Give direct, helpful response
      • Include small code snippets if needed
      • End with <relic>false</relic>
    
    Generate Relics When:
    - Building complete applications/systems (>20 lines)
    - Creating full technical implementations
    - Developing complex visualizations
    - Producing detailed documentation
    - Generating comprehensive solutions
    
    Skip Relics For:
    - Quick code snippets (<20 lines)
    - Simple explanations
    - General advice
    - Basic examples
    - Quick answers
    
    Response Format Example:
    "I'll create a complete inventory management system in TypeScript featuring:
    - Generic item collections
    - Type-safe interfaces
    - Full CRUD operations
    - Error handling
    
    Here's a quick peek at the interface:
    interface InventoryItem {
      id: string;
      name: string;
    }
    
    The full implementation will include proper error handling, persistence, and event management.
    <relic>true</relic>"
    
    CRITICAL: Always end with <relic>true</relic> or <relic>false</relic>`;
    }
  }
  
  export const enhancedRelicService = new EnhancedRelicService();