import { useState, useCallback, useEffect } from 'react';
import { Relic, RelicMetadata } from '../types/relic';
import { relicStore } from '../services/relicStore';

interface RelicPanelState {
  isOpen: boolean;
  isStreaming: boolean;
  streamingContent: string;
  activeRelic: Relic | null;
  error: string | null;
  generatingMessageId: string | null;
  pinnedRelics: Relic[]; // Add this
}

export const useRelicPanel = () => {
  const [state, setState] = useState<RelicPanelState>({
    isOpen: false,
    isStreaming: false,
    streamingContent: '',
    activeRelic: null,
    error: null,
    generatingMessageId: null,
    pinnedRelics: [] // Initialize empty pinned relics array
  });

   // Add pin management functions
   const handlePinRelic = useCallback((relic: Relic) => {
    setState(prev => ({
      ...prev,
      pinnedRelics: [...prev.pinnedRelics, relic]
    }));
  }, []);

  const handleUnpinRelic = useCallback((relicId: string) => {
    setState(prev => ({
      ...prev,
      pinnedRelics: prev.pinnedRelics.filter(r => r.metadata.id !== relicId)
    }));
  }, []);

  useEffect(() => {
    const handleGenerationStart = (event: CustomEvent<{ messageId: string }>) => {
      setState(prev => ({
        ...prev,
        isOpen: true,
        isStreaming: true,
        streamingContent: '',
        error: null,
        generatingMessageId: event.detail.messageId
      }));
    };

    const handleMetadataUpdate = (event: CustomEvent<{ 
      messageId: string; 
      metadata: RelicMetadata 
    }>) => {
      setState(prev => ({
        ...prev,
        activeRelic: { 
          metadata: event.detail.metadata, 
          content: '' 
        }
      }));
    };

    const handleContentUpdate = (event: CustomEvent<{ 
      messageId: string; 
      content: string 
    }>) => {
      setState(prev => {
        if (prev.generatingMessageId !== event.detail.messageId) return prev;
        
        return {
          ...prev,
          streamingContent: event.detail.content
        };
      });
    };

    const handleGenerationComplete = (event: CustomEvent<{ 
      messageId: string; 
      relic: Relic 
    }>) => {
      setState(prev => {
        if (prev.generatingMessageId !== event.detail.messageId) return prev;

        return {
          ...prev,
          isStreaming: false,
          streamingContent: '',
          activeRelic: event.detail.relic,
          generatingMessageId: null
        };
      });
    };

    const handleGenerationError = (event: CustomEvent<{
      messageId: string;
      error: string;
    }>) => {
      setState(prev => ({
        ...prev,
        isStreaming: false,
        error: event.detail.error,
        generatingMessageId: null
      }));
    };

    // Add event listeners
    window.addEventListener('relic-generation-start', handleGenerationStart as EventListener);
    window.addEventListener('relic-metadata-update', handleMetadataUpdate as EventListener);
    window.addEventListener('relic-content-update', handleContentUpdate as EventListener);
    window.addEventListener('relic-generation-complete', handleGenerationComplete as EventListener);
    window.addEventListener('relic-generation-error', handleGenerationError as EventListener);

    return () => {
      // Remove event listeners
      window.removeEventListener('relic-generation-start', handleGenerationStart as EventListener);
      window.removeEventListener('relic-metadata-update', handleMetadataUpdate as EventListener);
      window.removeEventListener('relic-content-update', handleContentUpdate as EventListener);
      window.removeEventListener('relic-generation-complete', handleGenerationComplete as EventListener);
      window.removeEventListener('relic-generation-error', handleGenerationError as EventListener);
    };
  }, []);

  const handleRelicOpen = useCallback((relicId: string) => {
    const relic = relicStore.getRelic(relicId);
    
    if (relic) {
        // Force a close-open cycle to trigger re-render
        setState(prev => ({
          ...prev,
          isOpen: false,
          activeRelic: null
        }));
        
        // Use setTimeout to ensure the close state is processed
        setTimeout(() => {
          setState(prev => ({
            ...prev,
            isOpen: true,
            activeRelic: relic,
            isStreaming: false,
            streamingContent: '',
            error: null
          }));
        }, 0);
      }
    }, []);

  const handleRelicClose = useCallback(() => {
    setState(prev => ({
      ...prev,
      isOpen: false,
      activeRelic: null,
      isStreaming: false,
      streamingContent: '',
      error: null,
      generatingMessageId: null
    }));
  }, []);

  return {
    ...state,
    handleRelicOpen,
    handleRelicClose,
    handlePinRelic,
    handleUnpinRelic,
  };
};