import React, { useState } from 'react';
import { X } from 'lucide-react';

interface AlertProps {
  children: React.ReactNode;
  variant?: 'default' | 'destructive';
  className?: string;
  onClose?: () => void;
}

export const Alert: React.FC<AlertProps> = ({ 
  children, 
  variant = 'default',
  className = '',
  onClose
}) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
    if (onClose) {
      onClose();
    }
  };

  if (!isVisible) return null;

  const baseStyles = 'p-4 rounded-lg border relative';
  const variantStyles = {
    default: 'bg-zinc-800 border-zinc-700 text-zinc-100',
    destructive: 'bg-red-900/50 border-red-500/50 text-red-200'
  };

  return (
    <div className={`${baseStyles} ${variantStyles[variant]} ${className} animate-fade-in`}>
      {children}
      <button
        onClick={handleClose}
        className={`absolute right-2 top-3.5 p-1 rounded-md transition-colors
          ${variant === 'destructive' 
            ? 'text-red-200 hover:bg-red-800/50' 
            : 'text-zinc-400 hover:bg-zinc-700/50'}`}
        aria-label="Close alert"
      >
        <X size={16} />
      </button>
    </div>
  );
};

export const AlertDescription: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <div className="text-sm pr-6">{children}</div>;
};