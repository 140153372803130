// components/chat/ChatThreadPage.tsx
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { Alert, AlertDescription } from '../ui/alert';
import { ProjectDocument } from '../../types/types';
import { documentStore } from '../../utils/documentStore';
import { ProjectDrawer } from '../projects/ProjectDrawer';
import ScrollToBottomButton from '../ui/ScrollToBottomButton';
import { Model } from './ModelSelector';
import { ChatThreadHeader } from './ChatThreadHeader';
import { MessageContainer } from './MessageContainer';
import { ChatInputContainer } from './ChatInputContainer';
import { useChat } from '../../hooks/useChat';
import { useScroll } from '../../hooks/useScroll';
import { useRelicPanel } from '../../hooks/useRelicPanel';
import ConfirmDialog from '../ui/ConfirmDialog';
import { RelicPanelContainer } from '../relics/RelicPanelContainer';

const defaultModel: Model = {
  id: 'sonnet',
  name: 'Claude 3.5 Sonnet (Latest)',
  description: 'Best for complex tasks and analysis',
  apiId: 'claude-3-5-sonnet-latest',
  capabilities: {
    imageInput: false
  }
};

interface LocationState {
  initialMessage?: string;
  initialAttachments?: File[];
  initialModel?: Model;
}

const ChatThreadPage: React.FC = () => {
  const { chatId } = useParams<{ chatId: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const initialMessageSentRef = useRef(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const [documents, setDocuments] = useState<ProjectDocument[]>([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentModel, setCurrentModel] = useState<Model>(() => {
    const state = location.state as LocationState;
    return state?.initialModel || defaultModel;
  });

  const {
    messages,
    isLoading,
    error,
    streamingContent,
    currentChatId,
    sendMessage,
    deleteChat
  } = useChat(chatId);

  const {
    shouldAutoScroll,
    showScrollButton,
    scrollToBottom
  } = useScroll(containerRef);

  // Handle initial message from navigation state
  useEffect(() => {
    const state = location.state as LocationState;
    if (state?.initialMessage && !initialMessageSentRef.current) {
      sendMessage(
        state.initialMessage,
        state.initialModel || currentModel,
        state.initialAttachments
      );
      initialMessageSentRef.current = true;

      // Clear the location state after sending initial message
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location.state, sendMessage, currentModel, navigate, location.pathname]);

  // Load documents
  useEffect(() => {
    const loadDocuments = () => {
      const storedDocuments = documentStore.getAllDocuments();
      setDocuments(storedDocuments);
    };

    loadDocuments();
    
    // Listen for document updates
    window.addEventListener('documents-updated', loadDocuments);
    return () => {
      window.removeEventListener('documents-updated', loadDocuments);
    };
  }, []);

  // Handle chat deletion with confirmation
  const handleDeleteChat = () => {
    deleteChat();
  };

  // Add useRelicPanel hook at this level
  const {
    isOpen: isRelicOpen,
    activeRelic,
    isStreaming: isRelicStreaming,
    streamingContent: relicStreamingContent,
    error: relicError,
    handleRelicOpen,
    handleRelicClose
  } = useRelicPanel();

  return (
    <>
    <div className="h-screen bg-zinc-900 flex flex-col">
      <ChatThreadHeader 
        currentChatId={currentChatId} 
        onDeleteChat={() => setShowDeleteConfirm(true)}
        onRelicSelect={handleRelicOpen}
      />

      {/* Error Display */}
      {error && (
        <Alert variant="destructive" className="mx-auto mt-4 max-w-4xl">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      {/* Main Chat Area */}
      <div ref={containerRef} className="flex-1 overflow-y-auto relative">
        <MessageContainer
          messages={messages}
          streamingContent={streamingContent}
          isLoading={isLoading}
          currentModel={currentModel}
          onScroll={() => {}}
          shouldAutoScroll={shouldAutoScroll}
          isRelicOpen={isRelicOpen} 
          onRelicSelect={handleRelicOpen}
        />
      </div>

      {/* Input Container */}
      <ChatInputContainer
        onSendMessage={sendMessage}
        isLoading={isLoading}
        currentModel={currentModel}
        onModelSelect={setCurrentModel}
      />

      {/* Scroll to Bottom Button */}
      <ScrollToBottomButton 
        show={showScrollButton} 
        onClick={scrollToBottom}
      />

      {/* Project Drawer */}
      <ProjectDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        onOpen={() => setIsDrawerOpen(true)}
      />

      {/* Add RelicPanel here at the root level */}
      <RelicPanelContainer
        activeRelic={activeRelic}
        isOpen={isRelicOpen}
        onClose={handleRelicClose}
        isStreaming={isRelicStreaming}
        streamingContent={relicStreamingContent}
        error={relicError}
      />
    </div>

    {/* Add the confirmation dialog */}
    <ConfirmDialog
        isOpen={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        onConfirm={handleDeleteChat}
        title="Delete Chat"
        message={`Are you sure you want to delete this chat thread? This action cannot be undone.`}
      />
    </>
  );
};

export default ChatThreadPage;