// components/chat/StreamingMessage.tsx
import React, { useState, useEffect } from 'react';
import { Loader2 } from 'lucide-react';
import SafeMarkdown from './SafeMarkdown';
import { Model } from './ModelSelector';
import { usePersona } from '../../hooks/usePersona';

interface StreamingMessageProps {
  content: string;
  isComplete: boolean;
  model?: Model;
}

export const StreamingMessage: React.FC<StreamingMessageProps> = ({ 
  content, 
  isComplete, 
  model,
}) => {
  const [displayContent, setDisplayContent] = useState(content); // Initialize with content
  const [isVisible, setIsVisible] = useState(false);
  const persona = usePersona();

  // Add this effect to update displayContent when content prop changes
  useEffect(() => {
    setDisplayContent(content);
  }, [content]); // This will update whenever content changes

  // Fade in animation
  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), 10);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div 
      className={`transform transition-all duration-300 ease-in-out
                  ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-2'}`}
    >
      {/* Header */}
      <div className="flex items-center gap-2 mb-2">
        <div className="flex items-center gap-2">
          <span className="text-zinc-300 font-medium">{persona.name}</span>
          {!isComplete && (
            <Loader2 className="w-4 h-4 text-blue-400 animate-spin" />
          )}
        </div>
        {model && (
          <span className="text-zinc-500 text-sm italic">
            using {model.name}
          </span>
        )}
        <span className="ml-auto text-zinc-500 text-sm">
          {new Date().toLocaleTimeString()}
        </span>
      </div>

      {/* Content */}
      <div className="pl-8">
        {displayContent ? (
          <div className="relative">
            <SafeMarkdown content={displayContent} />
            {!isComplete && (
              <div className="absolute bottom-0 left-0 w-full h-8 bg-gradient-to-t from-zinc-900 to-transparent" />
            )}
          </div>
        ) : (
          <div className="animate-pulse space-y-2">
            <div className="h-4 bg-zinc-800 rounded w-3/4"></div>
            <div className="h-4 bg-zinc-800 rounded w-1/2"></div>
          </div>
        )}
      </div>
    </div>
  );
};