// services/ragService.ts
import { improvedVectorStore } from './improvedVectorStore';
import { embeddingsService } from './localEmbeddingsService';
import { projectStore } from './projectStore';

interface DocumentContext {
    content: string;
    fileName: string;
    similarity: number;
  }

class RAGService {
  async getRelevantContext(query: string): Promise<string> {
    try {
      // Get active projects
      const activeProjects = projectStore.getAllProjects()
        .filter(project => project.isActive)
        .map(project => project.id);

      if (activeProjects.length === 0) {
        return '';
      }

      // Generate query embedding
      const queryEmbedding = await embeddingsService.generateQueryEmbedding(query);

      // Search for relevant vectors
      const results = await improvedVectorStore.findSimilarVectors(
        queryEmbedding,
        activeProjects,
        5
      );

      // Get document contents
      const contexts = await Promise.all(
        results.map(async (result): Promise<DocumentContext | null> => {
          try {
            const exists = await this.validateDocumentExists(result.metadata.fileName);
            if (!exists) {
              await improvedVectorStore.deleteDocumentVectors(result.metadata.documentId);
              return null;
            }

            const content = await window.fs.readFile(result.metadata.fileName, { 
              encoding: 'utf8' 
            });
            
            return {
              content,
              fileName: result.metadata.fileName,
              similarity: result.similarity
            };
          } catch (error) {
            console.error(`Error loading document ${result.metadata.fileName}:`, error);
            return null;
          }
        })
      );

      const validContexts = contexts.filter((ctx): ctx is DocumentContext => ctx !== null);

      if (validContexts.length === 0) {
        return '';
      }

      const formattedContext = validContexts
        .map(ctx => {
          const similarityPercentage = Math.round(ctx.similarity * 100);
          return `[${ctx.fileName}] (${similarityPercentage}% relevant):\n${ctx.content}\n`;
        })
        .join('\n---\n\n');

      return `Relevant context from your documents:\n\n${formattedContext}`;
    } catch (error) {
      console.error('Error getting RAG context:', error);
      return '';
    }
  }

  private async validateDocumentExists(fileName: string): Promise<boolean> {
    try {
      await window.fs.readFile(fileName, { encoding: 'utf8' });
      return true;
    } catch (error) {
      return false;
    }
  }
}

export const ragService = new RAGService();