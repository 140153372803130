// PersonaNameButton.tsx
import React from 'react';

interface PersonaNameButtonProps {
  name: string;
  onClick: () => void;
}

const PersonaNameButton: React.FC<PersonaNameButtonProps> = ({ name, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="text-white font-semibold hover:underline focus:outline-none rounded"
      aria-label="Open configuration for persona"
    >
      {name}
    </button>
  );
};

export default PersonaNameButton;
