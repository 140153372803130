// services/relicStore.ts

import { Relic } from '../types/relic';
import { chatStore } from '../utils/chatStore';

const RELICS_STORAGE_KEY = 'stored_relics';
const PINNED_RELICS_KEY = 'pinned_relics';

class RelicStoreService {
  private relics: Map<string, Relic> = new Map();
  private pinnedRelicIds: Set<string> = new Set();
  
  constructor() {
    this.loadRelics();
    this.loadPinnedState();
  }

  private loadPinnedState() {
    const pinnedIds = localStorage.getItem(PINNED_RELICS_KEY);
    if (pinnedIds) {
      try {
        const ids = JSON.parse(pinnedIds);
        this.pinnedRelicIds = new Set(ids);
      } catch (error) {
        console.error('Error loading pinned relics:', error);
      }
    }
  }

  private savePinnedState() {
    localStorage.setItem(
      PINNED_RELICS_KEY, 
      JSON.stringify(Array.from(this.pinnedRelicIds))
    );
  }

  public isPinned(relicId: string): boolean {
    return this.pinnedRelicIds.has(relicId);
  }

  public hasAnyPinnedRelics(): boolean {
    return this.pinnedRelicIds.size > 0;
  }

  public setPinned(relicId: string, isPinned: boolean) {
    const changed = isPinned !== this.pinnedRelicIds.has(relicId);
    
    if (changed) {
      if (isPinned) {
        this.pinnedRelicIds.add(relicId);
      } else {
        this.pinnedRelicIds.delete(relicId);
      }
      
      this.savePinnedState();
      
      window.dispatchEvent(new CustomEvent('relic-pin-changed', {
        detail: { 
          relicId,
          isPinned 
        }
      }));
  
      // Also dispatch general update
      window.dispatchEvent(new CustomEvent('relics-updated'));
    }
  }
  private loadRelics() {
    const stored = localStorage.getItem(RELICS_STORAGE_KEY);
    if (stored) {
      const relics = JSON.parse(stored);
      relics.forEach((relic: Relic) => {
        this.relics.set(relic.metadata.id, {
          ...relic,
          metadata: {
            ...relic.metadata,
            timestamp: new Date(relic.metadata.timestamp)
          }
        });
      });
    }
  }

  private saveRelics() {
    localStorage.setItem(
      RELICS_STORAGE_KEY, 
      JSON.stringify(Array.from(this.relics.values()))
    );
    window.dispatchEvent(new CustomEvent('relics-updated'));
  }

  public getRelic(id: string): Relic | undefined {
    return this.relics.get(id);
  }

  public addRelic(relic: Relic) {
    this.relics.set(relic.metadata.id, relic);
    this.saveRelics();

    // Update chat message with relic reference
    const chat = chatStore.getChat(relic.metadata.chatId);
    if (chat) {
      const updatedMessages = chat.messages.map(ch => 
        ch.id === relic.metadata.chatId
          ? {
              ...ch,
              relic: {
                id: relic.metadata.id,
                previewContent: this.generatePreview(relic)
              }
            }
          : ch
      );
      chatStore.updateChat(chat.id, updatedMessages);
    }
  }

  public updateRelic(id: string, content: string) {
    const relic = this.relics.get(id);
    if (relic) {
      this.relics.set(id, { ...relic, content });
      this.saveRelics();
    }
  }

  public deleteRelic(id: string) {
    // Check if relic exists before deletion
    if (!this.relics.has(id)) {
      console.warn(`Attempted to delete non-existent relic: ${id}`);
      return;
    }
  
    const wasPinned = this.pinnedRelicIds.has(id);
    if (wasPinned) {
      this.pinnedRelicIds.delete(id);
    }
  
    // Delete the relic immediately
    this.relics.delete(id);
    
    // Save state
    this.saveRelics();
    this.savePinnedState();
  
    // Dispatch events in the correct order
    if (wasPinned) {
      window.dispatchEvent(new CustomEvent('relic-pin-changed', {
        detail: { 
          relicId: id,
          isPinned: false
        }
      }));
    }
  
    window.dispatchEvent(new CustomEvent('relic-deleted', {
      detail: { relicId: id }
    }));
  
    window.dispatchEvent(new CustomEvent('relics-updated'));
  }

  public deleteRelicsForChat(chatId: string) {
    const chatRelics = this.getRelicsForChat(chatId);
    chatRelics.forEach(relic => this.deleteRelic(relic.metadata.id));
  }

  private generatePreview(relic: Relic): string {
    // Generate a condensed preview based on relic type
    switch (relic.metadata.type) {
      case 'code':
        return relic.content.split('\n').slice(0, 3).join('\n') + '...';
      case 'markdown':
        return relic.content.slice(0, 150) + '...';
      default:
        return '[Click to view relic content]';
    }
  }

  public getRelicsForChat(chatId: string): Relic[] {
    return Array.from(this.relics.values())
      .filter(relic => relic.metadata.chatId === chatId)
      .sort((a, b) => b.metadata.timestamp.getTime() - a.metadata.timestamp.getTime());
  }
}

export const relicStore = new RelicStoreService();