// ProjectIndicator.tsx
import React, { useState } from 'react';
import { FolderOpen } from 'lucide-react';

interface ProjectIndicatorProps {
  onClick: () => void;
}

const ProjectIndicator: React.FC<ProjectIndicatorProps> = ({ onClick }) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);

  return (
    <button
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`fixed z-2 p-2 right-2 top-2.5 text-zinc-400 hover:text-white transition-all duration-300 
                  transform ${isHovering ? 'wiggle-hover tilt-right' : ''}`}
      aria-label="Open project drawer"
    >
      <FolderOpen size={20} />
    </button>
  );
};

export default ProjectIndicator;
