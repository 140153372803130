import { API_CONFIG, fetchWithConfig, getApiUrl } from "../config/api";

// Use the Web Crypto API for encryption/decryption
class ApiKeyService {
    private readonly STORAGE_KEY = 'encrypted_anthropic_key';
    private readonly SALT_KEY = 'key_salt';
    private readonly IV_KEY = 'key_iv';
    private readonly SECRET = process.env.SECRET || 'removed';
    
    // Get encryption key from a password (the app's secret)
    private async getEncryptionKey(password: string, salt: Uint8Array): Promise<CryptoKey> {
      const encoder = new TextEncoder();
      const keyMaterial = await crypto.subtle.importKey(
        'raw',
        encoder.encode(password),
        'PBKDF2',
        false,
        ['deriveBits', 'deriveKey']
      );
      
      return crypto.subtle.deriveKey(
        {
          name: 'PBKDF2',
          salt,
          iterations: 310000,
          hash: 'SHA-256'
        },
        keyMaterial,
        { name: 'AES-GCM', length: 256 },
        false,
        ['encrypt', 'decrypt']
      );
    }
  
    // Encrypt API key before storing
    private async encryptKey(apiKey: string): Promise<void> {
      try {
        // Generate random salt and IV
        const salt = crypto.getRandomValues(new Uint8Array(16));
        const iv = crypto.getRandomValues(new Uint8Array(12));
        
        // Get encryption key
        const key = await this.getEncryptionKey(this.SECRET, salt);
        
        // Encrypt the API key
        const encoder = new TextEncoder();
        const encryptedData = await crypto.subtle.encrypt(
          {
            name: 'AES-GCM',
            iv
          },
          key,
          encoder.encode(apiKey)
        );
  
        // Store encrypted data and parameters
        const encryptedArray = new Uint8Array(encryptedData);
        const db = await this.openDB();
        
        await Promise.all([
          this.setItem(db, this.STORAGE_KEY, Array.from(encryptedArray)),
          this.setItem(db, this.SALT_KEY, Array.from(salt)),
          this.setItem(db, this.IV_KEY, Array.from(iv))
        ]);
  
      } catch (error) {
        console.error('Error encrypting API key:', error);
        throw new Error('Failed to securely store API key');
      }
    }
  
    // Decrypt stored API key
    private async decryptKey(password: string): Promise<string> {
      try {
        const db = await this.openDB();
        
        // Get stored encrypted data and parameters
        const [encryptedData, salt, iv] = await Promise.all([
          this.getItem(db, this.STORAGE_KEY),
          this.getItem(db, this.SALT_KEY),
          this.getItem(db, this.IV_KEY)
        ]);
  
        if (!encryptedData || !salt || !iv) {
          throw new Error('No API key found');
        }
  
        // Convert stored arrays back to Uint8Arrays
        const encryptedArray = new Uint8Array(encryptedData);
        const saltArray = new Uint8Array(salt);
        const ivArray = new Uint8Array(iv);
  
        // Get decryption key
        const key = await this.getEncryptionKey(this.SECRET, saltArray);
        
        // Decrypt the data
        const decryptedData = await crypto.subtle.decrypt(
          {
            name: 'AES-GCM',
            iv: ivArray
          },
          key,
          encryptedArray
        );
  
        const decoder = new TextDecoder();
        return decoder.decode(decryptedData);
  
      } catch (error) {
        console.error('Error decrypting API key:', error);
        throw new Error('Failed to retrieve API key');
      }
    }
  
    // IndexedDB utilities
    private async openDB(): Promise<IDBDatabase> {
      return new Promise((resolve, reject) => {
        const request = indexedDB.open('ApiKeyStore', 1);
        
        request.onerror = () => reject(request.error);
        request.onsuccess = () => resolve(request.result);
        
        request.onupgradeneeded = (event) => {
          const db = (event.target as IDBOpenDBRequest).result;
          if (!db.objectStoreNames.contains('keyStore')) {
            db.createObjectStore('keyStore');
          }
        };
      });
    }
  
    private async setItem(db: IDBDatabase, key: string, value: any): Promise<void> {
      return new Promise((resolve, reject) => {
        const transaction = db.transaction(['keyStore'], 'readwrite');
        const store = transaction.objectStore('keyStore');
        const request = store.put(value, key);
        
        request.onerror = () => reject(request.error);
        request.onsuccess = () => resolve();
      });
    }
  
    private async getItem(db: IDBDatabase, key: string): Promise<any> {
      return new Promise((resolve, reject) => {
        const transaction = db.transaction(['keyStore'], 'readonly');
        const store = transaction.objectStore('keyStore');
        const request = store.get(key);
        
        request.onerror = () => reject(request.error);
        request.onsuccess = () => resolve(request.result);
      });
    }
  
    // Public methods
    async hasStoredKey(): Promise<boolean> {
      try {
        const db = await this.openDB();
        const data = await this.getItem(db, this.STORAGE_KEY);
        return !!data;
      } catch {
        return false;
      }
    }

    private async validateApiKeyFormat(apiKey: string): Promise<void> {
      const trimmedKey = apiKey.trim();
      
      if (!trimmedKey) {
        throw new Error('API key cannot be empty');
      }
      
      if (!trimmedKey.startsWith('sk-ant')) {
        throw new Error('Invalid API key format - must be a valid Anthropic API key');
      }
      
      if (trimmedKey.length < 64) { // Minimum length for Anthropic keys
        throw new Error('API key appears to be truncated or invalid');
      }
    }
  
    async storeApiKey(apiKey: string): Promise<void> {
      try {
        // Validate key format
        await this.validateApiKeyFormat(apiKey);
        
        // Validate with backend
        try {
          const response = await fetchWithConfig('validateKey', {
            method: 'POST',
            headers: {
              'X-Api-Key': apiKey.trim()
            }
          });

          if (!response.ok) {
            throw new Error('API key validation failed');
          }
        } catch (error) {
          console.error('API validation error:', error);
          throw new Error('Failed to validate API key with server: ' + error);
        }

        // Encrypt and store
        await this.encryptKey(apiKey.trim());
        
        // Verify storage
        const storedKey = await this.getApiKey();
        if (!storedKey || !storedKey.startsWith('sk-ant')) {
          throw new Error('Key verification failed after storage');
        }

        // Notify components
        window.dispatchEvent(new CustomEvent('api-key-updated'));
        
      } catch (error) {
        console.error('Error storing API key:', {
          baseUrl: API_CONFIG.getBaseUrl(),
          environment: process.env.NODE_ENV,
          error: error
        });
        throw error;
      }
    }
  
    async getApiKey(): Promise<string> {
      const appSecret = 'your-app-secret-here';
      return this.decryptKey(appSecret);
    }
  
    async clearApiKey(): Promise<void> {
      const db = await this.openDB();
      const transaction = db.transaction(['keyStore'], 'readwrite');
      const store = transaction.objectStore('keyStore');
      
      await Promise.all([
        store.delete(this.STORAGE_KEY),
        store.delete(this.SALT_KEY),
        store.delete(this.IV_KEY)
      ]);
    }
  }
  
  export const apiKeyService = new ApiKeyService();