import React, { useState, useEffect, useCallback } from 'react';
import { RelicPanel } from './RelicPanel';
import { Relic } from '../../types/relic';
import { relicStore } from '../../services/relicStore';

interface PinnedRelic {
  relic: Relic;
  isPinned: boolean;
  zIndex: number;
}

const PINNED_RELICS_KEY = 'pinned-relics';

interface RelicPanelContainerProps {
  activeRelic: Relic | null;
  isOpen: boolean;
  onClose: () => void;
  isStreaming: boolean;
  streamingContent: string;
  error: string | null;
}

export const RelicPanelContainer: React.FC<RelicPanelContainerProps> = ({
  activeRelic,
  isOpen,
  onClose,
  isStreaming,
  streamingContent,
  error
}) => {
  const [pinnedRelics, setPinnedRelics] = useState<PinnedRelic[]>([]);
  const [baseZIndex] = useState(20); // Base z-index for panels

  // Load pinned relics from localStorage on mount
  useEffect(() => {
    const storedPinned = localStorage.getItem(PINNED_RELICS_KEY);
    if (storedPinned) {
      try {
        const parsed = JSON.parse(storedPinned);
        setPinnedRelics(parsed);
      } catch (error) {
        console.error('Error loading pinned relics:', error);
      }
    }
  }, []);

  // Save pinned relics to localStorage when changed
  useEffect(() => {
    if (pinnedRelics.length > 0) {
        pinnedRelics.forEach(x => relicStore.setPinned(x.relic.metadata.id, x.isPinned))
    } else {
      localStorage.removeItem(PINNED_RELICS_KEY);
    }
  }, [pinnedRelics]);

  // Bring panel to front - now memoized
  const handlePanelFocus = useCallback((relicId: string) => {
    setPinnedRelics(current => {
      const highestZ = Math.max(...current.map(r => r.zIndex), baseZIndex);
      return current.map(pr => 
        pr.relic.metadata.id === relicId 
          ? { ...pr, zIndex: highestZ + 1 }
          : pr
      );
    });
  }, [baseZIndex]);

  const handleRelicCleanup = useCallback((relic: Relic) => {
    // Remove from pinned relics first
    setPinnedRelics(current => current.filter(pr => pr.relic.metadata.id !== relic.metadata.id));
    
    // Update store state
    relicStore.setPinned(relic.metadata.id, false);
    
    // Dispatch unpinned event
    window.dispatchEvent(new CustomEvent('relic-pin-changed', {
      detail: { 
        relicId: relic.metadata.id,
        isPinned: false
      }
    }));

    // If this is the active relic, also call the main onClose
    if (activeRelic?.metadata.id === relic.metadata.id) {
      onClose();
    }
  }, [activeRelic, onClose]);

  // Handle pinning/unpinning relics
  const handlePinToggle = useCallback((relic: Relic) => {
    const isCurrentlyPinned = pinnedRelics.some(pr => pr.relic.metadata.id === relic.metadata.id);
    
    if (isCurrentlyPinned) {
      handleRelicCleanup(relic);
    } else {
      const highestZ = Math.max(...pinnedRelics.map(r => r.zIndex), baseZIndex);
      setPinnedRelics(current => [...current, {
        relic,
        isPinned: true,
        zIndex: highestZ + 1
      }]);
      relicStore.setPinned(relic.metadata.id, true);
      
      // Dispatch pinned event
      window.dispatchEvent(new CustomEvent('relic-pin-changed', {
        detail: { 
          relicId: relic.metadata.id,
          isPinned: true
        }
      }));

      // If this is the active relic, also call the main onClose
    if (activeRelic?.metadata.id === relic.metadata.id) {
        onClose();
      }
    }
  }, [pinnedRelics, baseZIndex, handleRelicCleanup]);

  // Handle opening a new relic
  useEffect(() => {
    if (isOpen && activeRelic) {
      // Close any unpinned relics by filtering them out
      setPinnedRelics(current => 
        current.filter(pr => pr.isPinned)
      );
    }
  }, [isOpen, activeRelic]);

  // Listen for relic deletions to clean up pinned state
  useEffect(() => {
    const handleRelicDeleted = (event: CustomEvent<{ relicId: string }>) => {
      const relicId = event.detail.relicId;
      const pinnedRelic = pinnedRelics.find(pr => pr.relic.metadata.id === relicId);
      if (pinnedRelic) {
        handleRelicCleanup(pinnedRelic.relic);
      }

      if (activeRelic?.metadata.id === relicId) {
        onClose();
      }
    };

    window.addEventListener('relic-deleted', handleRelicDeleted as EventListener);
    return () => {
      window.removeEventListener('relic-deleted', handleRelicDeleted as EventListener);
    };
  }, [pinnedRelics, handleRelicCleanup]);

  return (
    <>
      {/* Render pinned relics */}
      {pinnedRelics.map(({ relic, zIndex }) => (
        <RelicPanel
          key={relic.metadata.id}
          relic={relic}
          isOpen={true}
          onClose={() => handlePinToggle(relic)}
          isStreaming={false}
          streamingContent=""
          error={null}
          isPinned={true}
          onPinToggle={() => handlePinToggle(relic)}
          zIndex={zIndex}
          onFocus={() => handlePanelFocus(relic.metadata.id)}
        />
      ))}

      {/* Render active relic if it's not already pinned */}
      {isOpen && activeRelic && !pinnedRelics.some(pr => 
        pr.relic.metadata.id === activeRelic.metadata.id
      ) && (
        <RelicPanel
          relic={activeRelic}
          isOpen={isOpen}
          onClose={onClose}
          isStreaming={isStreaming}
          streamingContent={streamingContent}
          error={error}
          isPinned={false}
          onPinToggle={() => handlePinToggle(activeRelic)}
          zIndex={baseZIndex}
          onFocus={() => handlePanelFocus(activeRelic.metadata.id)}
        />
      )}
    </>
  );
};