import React, { useState, useEffect } from 'react';
import { Key, CheckCircle2, XCircle } from 'lucide-react';
import { ApiKeyModal } from './ApiKeyModal';
import { apiKeyService } from '../../services/ApiKeyService';

export default function ApiKeyIndicator() {
  const [hasKey, setHasKey] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isChecking, setIsChecking] = useState(true);

  const checkApiKey = async () => {
    setIsChecking(true);
    try {
      const hasStoredKey = await apiKeyService.hasStoredKey();
      setHasKey(hasStoredKey);
    } catch (error) {
      console.error('Error checking API key:', error);
    } finally {
      setIsChecking(false);
    }
  };

  useEffect(() => {
    checkApiKey();
  }, []);

  return (
    <div className="flex items-center gap-2">
      {/* Status Indicator */}
      <div className="flex items-center gap-1.5 px-2 py-1 rounded-md bg-zinc-800/50">
        {isChecking ? (
          <div className="w-4 h-4 rounded-full border-2 border-zinc-600 border-t-zinc-400 animate-spin" />
        ) : hasKey ? (
          <CheckCircle2 className="w-4 h-4 text-green-400" />
        ) : (
          <XCircle className="w-4 h-4 text-red-400" />
        )}
        <span className="text-sm text-zinc-400">
          {isChecking ? 'Checking API key' : 
           hasKey ? 'API key stored' : 'No API key'}
        </span>
      </div>

      {/* Manage Button */}
      <button
        onClick={() => setShowModal(true)}
        className="flex items-center gap-2 px-3 py-1.5 text-sm 
                 bg-blue-500/10 text-blue-400 hover:bg-blue-500/20 
                 rounded-md transition-colors"
      >
        <Key className="w-4 h-4" />
        Manage API Key
      </button>

      {/* Modal */}
      <ApiKeyModal 
        isOpen={showModal} 
        onClose={() => setShowModal(false)}
        onKeyUpdate={checkApiKey}
      />
    </div>
  );
}