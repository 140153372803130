import React, { useState, useEffect } from 'react';
import { Key, X, Eye, EyeOff } from 'lucide-react';
import { apiKeyService } from '../../services/ApiKeyService';

interface ApiKeyModalProps {
  isOpen: boolean;
  onClose: () => void;
  onKeyUpdate?: () => void;
}

export const ApiKeyModal: React.FC<ApiKeyModalProps> = ({ isOpen, onClose, onKeyUpdate }) => {
  const [apiKey, setApiKey] = useState('');
  const [showKey, setShowKey] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [hasStoredKey, setHasStoredKey] = useState(false);

  useEffect(() => {
    const checkStoredKey = async () => {
      const hasKey = await apiKeyService.hasStoredKey();
      setHasStoredKey(hasKey);
    };
    checkStoredKey();
  }, [isOpen]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    try {
      if (!apiKey.trim()) {
        throw new Error('API key is required');
      }

      if (!apiKey.startsWith('sk-')) {
        throw new Error('Invalid API key format');
      }

      await apiKeyService.storeApiKey(apiKey);
      setApiKey('');
      setShowKey(false);
      setHasStoredKey(true);
      onKeyUpdate?.();
      onClose();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to save API key');
    }
  };

  const handleClearKey = async () => {
    try {
      await apiKeyService.clearApiKey();
      setHasStoredKey(false);
      setApiKey('');
      onKeyUpdate?.();
    } catch (err) {
      setError('Failed to clear API key');
    }
  };

  if (!isOpen) return null;

  return (
    <>
    {/* Backdrop */}
    {isOpen && (
        <div
          className="fixed inset-0 bg-black/50 backdrop-blur-sm z-80"
          onClick={onClose}
        />
      )}
    <div className="fixed inset-0 flex items-center justify-center p-4">
      <div className="bg-zinc-900 rounded-lg w-full max-w-md">
        {/* Header */}
        <div className="flex items-center justify-between p-6 border-b border-zinc-800">
          <div className="flex items-center gap-2">
            <Key className="text-zinc-400" size={20} />
            <h2 className="text-lg font-semibold text-white">API Key Management</h2>
          </div>
          <button
            onClick={onClose}
            className="text-zinc-400 hover:text-zinc-300 transition-colors"
          >
            <X size={20} />
          </button>
        </div>

        {/* Content */}
        <form onSubmit={handleSubmit} className="p-6 space-y-4">
          <div className="space-y-2">
            <label className="block text-sm font-medium text-zinc-300">
              Anthropic API Key
            </label>
            <div className="relative">
              <input
                type={showKey ? 'text' : 'password'}
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
                className="w-full px-3 py-2 bg-zinc-800 border border-zinc-700 
                         rounded-md text-white placeholder-zinc-500 pr-10
                         focus:outline-none focus:ring-2 focus:ring-blue-500 
                         focus:border-transparent"
                placeholder="sk-..."
              />
              <button
                type="button"
                onClick={() => setShowKey(!showKey)}
                className="absolute right-2 top-1/2 -translate-y-1/2 
                         text-zinc-400 hover:text-zinc-300 transition-colors"
              >
                {showKey ? <EyeOff size={16} /> : <Eye size={16} />}
              </button>
            </div>
            <p className="text-xs text-zinc-400">
              Your API key will be encrypted and stored locally
            </p>
          </div>

          {error && (
            <div className="p-3 bg-red-900/50 border border-red-500/50 
                         rounded-lg text-red-200 text-sm">
              {error}
            </div>
          )}

          <div className="flex justify-between pt-4">
            {hasStoredKey && (
              <button
                type="button"
                onClick={handleClearKey}
                className="px-4 py-2 text-red-400 hover:text-red-300 transition-colors"
              >
                Clear Stored Key
              </button>
            )}
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-lg 
                       hover:bg-blue-500 transition-colors ml-auto"
            >
              {hasStoredKey ? 'Update Key' : 'Save Key'}
            </button>
          </div>
        </form>
      </div>
    </div>
    </>
  );
};