// types/project.ts

export interface Project {
  id: string;
  name: string;
  description?: string;
  createdAt: Date;
  updatedAt: Date;
  isActive: boolean;  
  documents: ProjectDocument[];
  contextConfig: ProjectContextConfig; // New field for RAG/cache settings
}

export interface ProjectContextConfig {
  useRag: boolean;
  usePromptCache: boolean;
  strategy: 'rag-only' | 'cache-only' | 'hybrid';
}
  
  export interface ProjectDocument {
    id: string;
    projectId: string;
    name: string;
    type: string;
    uploadedAt: Date;
    contentHash?: string;  // For tracking changes/duplicates
  }
  
  // For creating a new project
export interface CreateProjectInput {
  name: string;
  description?: string;
  isActive?: boolean;
  contextConfig?: ProjectContextConfig;
}
  
  // For updating an existing project
  export interface UpdateProjectInput {
    name?: string;
    description?: string;
    isActive?: boolean;
    contextConfig?: Partial<ProjectContextConfig>;
  }
  
  // For API responses
  export interface ProjectResponse {
    success: boolean;
    message?: string;
    project?: Project;
  }
  
  // For managing project selection
  export interface ProjectSelection {
    projectId: string;
    isSelected: boolean;
  }

  export const DEFAULT_CONTEXT_CONFIG: ProjectContextConfig = {
    useRag: true,
    usePromptCache: false,
    strategy: 'rag-only'
  };