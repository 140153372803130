export const API_CONFIG = {
  getBaseUrl: () => {
    if (process.env.NODE_ENV === 'development') {
      return 'http://localhost:8080';
    }
    return 'https://allain-express-server-production.up.railway.app';
  },
  
  // Add environment-specific configurations
  cors: {
    credentials: 'omit' as RequestCredentials, // Don't send credentials by default
    mode: 'cors' as RequestMode
  },
  
  // Add request timeout
  timeout: 10000, // 10 seconds
  
  // Add retry configuration
  retry: {
    maxAttempts: 3,
    backoffFactor: 2,
    initialDelay: 1000
  }
};

export const getApiUrl = (endpoint: string): string => {
  const baseUrl = API_CONFIG.getBaseUrl();
  return `${baseUrl}/api/${endpoint}`.replace(/([^:]\/)\/+/g, '$1'); // Normalize URL
};

// Helper for making API requests with timeout and retries
export const fetchWithConfig = async (
  endpoint: string,
  options: RequestInit & { timeout?: number } = {}
): Promise<Response> => {
  const url = getApiUrl(endpoint);
  const controller = new AbortController();
  
  // Set up timeout
  const timeoutId = setTimeout(() => {
    controller.abort();
  }, options.timeout || API_CONFIG.timeout);
  
  try {
    const response = await fetch(url, {
      ...options,
      ...API_CONFIG.cors,
      signal: controller.signal,
      headers: {
        'Content-Type': 'application/json',
        ...options.headers,
      }
    });
    
    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
    }
    
    return response;
  } catch (error) {
    if (error === 'AbortError') {
      throw new Error(`Request timeout after ${API_CONFIG.timeout}ms`);
    }
    throw error;
  } finally {
    clearTimeout(timeoutId);
  }
};