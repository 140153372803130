import React, { useEffect, useRef, useState } from 'react';
import { X, Copy, Check, Maximize2, Minimize2, Boxes, Loader2, AlertCircle, RefreshCw, Trash2, Pin } from 'lucide-react';
import { Relic } from '../../types/relic';
import { Alert, AlertDescription } from '../ui/alert';
import SafeMarkdown from '../chat/SafeMarkdown';
import { relicStore } from '../../services/relicStore';
import ConfirmDialog from '../ui/ConfirmDialog';

interface RelicPanelProps {
  relic: Relic | null;
  isOpen: boolean;
  onClose: () => void;
  isStreaming: boolean;
  streamingContent: string;
  error: string | null;
  isPinned?: boolean;
  onPinToggle: () => void;
  zIndex?: number; // Add this to manage layering
  onFocus: () => void;  // New prop for handling focus
}

interface PanelPosition {
  x: number;
  y: number;
  width: number;
  height: number;
}


interface Position {
  x: number;
  y: number;
}

interface Dimensions {
  width: number;
  height: number;
}

type ResizeDirection = 
  | 'n' | 's' | 'e' | 'w' 
  | 'ne' | 'nw' | 'se' | 'sw' 
  | null;

  const STORAGE_KEY = 'relic-panel-position';
  // Minimum dimensions constraints
  const MIN_WIDTH = 320;
  const MIN_HEIGHT = 200;

const loadSavedPosition = (): PanelPosition | null => {
  try {
    const saved = localStorage.getItem(STORAGE_KEY);
    if (saved) {
      const position: PanelPosition = JSON.parse(saved);
      // Validate saved position is within current viewport and has valid dimensions
      if (position.x >= 0 && 
          position.y >= 0 &&
          position.width >= MIN_WIDTH &&
          position.height >= MIN_HEIGHT &&
          position.x + position.width <= window.innerWidth &&
          position.y + position.height <= window.innerHeight) {
        return position;
      }
    }
    return null;
  } catch (error) {
    console.warn('Error loading saved panel position:', error);
    return null;
  }
};


  const savePosition = (position: PanelPosition) => {
    try {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(position));
    } catch (error) {
      console.warn('Error saving panel position:', error);
    }
  };

// Calculate initial dimensions based on viewport
const calculatePanelDimensions = (viewportWidth: number): Dimensions => {
  if (viewportWidth < 640) {
    return {
      width: Math.min(viewportWidth - 32, 400),
      height: 500
    };
  } else if (viewportWidth < 1024) {
    return {
      width: Math.min(viewportWidth * 0.7, 600),
      height: 600
    };
  } else {
    return {
      width: Math.min(viewportWidth * 0.5, 800),
      height: Math.min(window.innerHeight * 0.7, 800)
    };
  }
};

// Calculate initial position based on viewport and panel size
const calculateInitialPosition = (
  viewportWidth: number, 
  viewportHeight: number,
  panelWidth: number,
  panelHeight: number
): PanelPosition => {
  return {
    x: (viewportWidth - panelWidth) / 1.05,
    y: Math.min(100, (viewportHeight - panelHeight) / 4),
    width: Math.min(viewportWidth * 0.5, 800),
    height: Math.min(window.innerHeight * 0.7, 800)
  };
};

export const RelicPanel: React.FC<RelicPanelProps> = ({
  relic,
  isOpen,
  onClose,
  isStreaming,
  streamingContent,
  error,
  isPinned,
  onPinToggle,
}) => {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const [copied, setCopied] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [resizeDirection, setResizeDirection] = useState<ResizeDirection>(null);
  const [dimensions, setDimensions] = useState(() => {
    const savedPosition = loadSavedPosition();
    if (savedPosition) {
      return {
        width: savedPosition.width,
        height: savedPosition.height
      };
    }
    return calculatePanelDimensions(window.innerWidth);
  });
  const [position, setPosition] = useState<PanelPosition>(() => {
    const savedPosition = loadSavedPosition();
    if (savedPosition) {
      return savedPosition;
    }
    return calculateInitialPosition(
      window.innerWidth, 
      window.innerHeight, 
      dimensions.width, 
      dimensions.height
    );
  });
  
  const dragStartPos = useRef({ x: 0, y: 0 });
  const resizeStartPos = useRef({ x: 0, y: 0 });
  const resizeStartDimensions = useRef({ width: 0, height: 0 });
  const resizeStartPosition = useRef({ x: 0, y: 0 });
  const lastScrollTop = useRef(0);
  const initialPanelPos = useRef<Position>({ x: 0, y: 0 });

   // Handle resize start
   const handleResizeStart = (e: React.MouseEvent, direction: ResizeDirection) => {
    if (isMaximized) return;
    e.preventDefault();
    e.stopPropagation();
    
    setIsResizing(true);
    setResizeDirection(direction);
    resizeStartPos.current = { x: e.clientX, y: e.clientY };
    resizeStartDimensions.current = { ...dimensions };
    resizeStartPosition.current = { ...position };
  };

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      if (!isMaximized) {
        const newDimensions = calculatePanelDimensions(window.innerWidth);
        setDimensions(newDimensions);
        
        // Recalculate position to keep panel in viewport
        const newPosition = calculateInitialPosition(
          window.innerWidth,
          window.innerHeight,
          newDimensions.width,
          newDimensions.height
        );
        setPosition(newPosition);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMaximized]);

  // Handle resize
  useEffect(() => {
    const handleResize = (e: MouseEvent) => {
      if (!isResizing || !resizeDirection) return;

      const deltaX = e.clientX - resizeStartPos.current.x;
      const deltaY = e.clientY - resizeStartPos.current.y;
      
      let newWidth = resizeStartDimensions.current.width;
      let newHeight = resizeStartDimensions.current.height;
      let newX = resizeStartPosition.current.x;
      let newY = resizeStartPosition.current.y;

      // Handle different resize directions
      if (resizeDirection.includes('e')) {
        newWidth = Math.max(MIN_WIDTH, resizeStartDimensions.current.width + deltaX);
      }
      if (resizeDirection.includes('w')) {
        const maxDeltaX = resizeStartDimensions.current.width - MIN_WIDTH;
        const boundedDeltaX = Math.max(-maxDeltaX, deltaX);
        newWidth = resizeStartDimensions.current.width - boundedDeltaX;
        newX = resizeStartPosition.current.x + boundedDeltaX;
      }
      if (resizeDirection.includes('s')) {
        newHeight = Math.max(MIN_HEIGHT, resizeStartDimensions.current.height + deltaY);
      }
      if (resizeDirection.includes('n')) {
        const maxDeltaY = resizeStartDimensions.current.height - MIN_HEIGHT;
        const boundedDeltaY = Math.max(-maxDeltaY, deltaY);
        newHeight = resizeStartDimensions.current.height - boundedDeltaY;
        newY = resizeStartPosition.current.y + boundedDeltaY;
      }

      // Update dimensions and position
      setDimensions({ width: newWidth, height: newHeight });
      setPosition({ x: newX, y: newY, width: newWidth, height: newHeight });
    };

    const handleResizeEnd = () => {
      if (isResizing) {
        setIsResizing(false);
        setResizeDirection(null);
        // Save new dimensions and position
        savePosition({
          ...position,
          width: dimensions.width,
          height: dimensions.height
        });
      }
    };

    if (isResizing) {
      window.addEventListener('mousemove', handleResize);
      window.addEventListener('mouseup', handleResizeEnd);
    }

    return () => {
      window.removeEventListener('mousemove', handleResize);
      window.removeEventListener('mouseup', handleResizeEnd);
    };
  }, [isResizing, resizeDirection]);

  // Handle scroll events
  useEffect(() => {
    const container = contentRef.current;
    if (!container) return;

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = container;
      const isAtBottom = scrollHeight - (scrollTop + clientHeight) < 50;
      
      if (Math.abs(scrollTop - lastScrollTop.current) > 10) {
        lastScrollTop.current = scrollTop;
      }
    };

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, []);

  // Reset position when opening
  useEffect(() => {
    if (isOpen) {
      const savedPosition = loadSavedPosition();
      if (!savedPosition) {
        const newDimensions = calculatePanelDimensions(window.innerWidth);
        setDimensions(newDimensions);
        
        const newPosition = calculateInitialPosition(
          window.innerWidth,
          window.innerHeight,
          newDimensions.width,
          newDimensions.height
        );
        setPosition(newPosition);
      }
    }
  }, [isOpen]);

  // Dragging handlers
  useEffect(() => {
    const handleMouseMove = (e: globalThis.MouseEvent): void => {
      if (!isDragging) return;
      
      const deltaX = e.clientX - dragStartPos.current.x;
      const deltaY = e.clientY - dragStartPos.current.y;
      
      const newX = initialPanelPos.current.x + deltaX;
      const newY = initialPanelPos.current.y + deltaY;

      // Constrain to viewport bounds
      const maxX = window.innerWidth - dimensions.width;
      const maxY = window.innerHeight - dimensions.height;

      const constrainedX = Math.max(0, Math.min(newX, maxX));
      const constrainedY = Math.max(0, Math.min(newY, maxY));

      setPosition(prev => ({ ...prev, x: constrainedX, y: constrainedY }));
    };

    const handleMouseUp = (): void => {
      if (isDragging) {
        setIsDragging(false);
        document.body.style.userSelect = '';
        // Save position after drag
        savePosition(position);
      }
    };

    if (isDragging) {
      document.body.style.userSelect = 'none';
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      document.body.style.userSelect = '';
    };
  }, [isDragging, dimensions]);

  // Handle resize events
  useEffect(() => {
    const handleResize = (e: MouseEvent) => {
      if (!isResizing || !resizeDirection) return;

      const deltaX = e.clientX - dragStartPos.current.x;
      const deltaY = e.clientY - dragStartPos.current.y;

      const newPosition = { ...position };

      if (resizeDirection.includes('e')) {
        newPosition.width = Math.max(MIN_WIDTH, position.width + deltaX);
      }
      if (resizeDirection.includes('w')) {
        const maxDeltaX = position.width - MIN_WIDTH;
        const boundedDeltaX = Math.max(-maxDeltaX, deltaX);
        newPosition.width = position.width - boundedDeltaX;
        newPosition.x = position.x + boundedDeltaX;
      }
      if (resizeDirection.includes('s')) {
        newPosition.height = Math.max(MIN_HEIGHT, position.height + deltaY);
      }
      if (resizeDirection.includes('n')) {
        const maxDeltaY = position.height - MIN_HEIGHT;
        const boundedDeltaY = Math.max(-maxDeltaY, deltaY);
        newPosition.height = position.height - boundedDeltaY;
        newPosition.y = position.y + boundedDeltaY;
      }

      setPosition(newPosition);
    };

    const handleMouseUp = () => {
      if (isResizing) {
        setIsResizing(false);
        setResizeDirection(null);
        // Save new dimensions
        savePosition(position);
      }
    };

    if (isResizing) {
      document.addEventListener('mousemove', handleResize);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleResize);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing, resizeDirection, position]);

  

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>): void => {
    if ((e.target as HTMLElement).closest('button')) return;
    setIsDragging(true);
    dragStartPos.current = { x: e.clientX, y: e.clientY };
    initialPanelPos.current = { ...position };
  };

  const handleMaximize = () => {
    setIsMaximized(!isMaximized);
    if (!isMaximized) {
      // Save current position before maximizing
      savePosition(position);
    }
  };

  // Ensure position is always saved, regardless of pin state
  useEffect(() => {
    if (!isDragging && !isResizing && position) {
      savePosition(position);
    }
  }, [position, isDragging, isResizing]);

  const handleCopy = async (): Promise<void> => {
    const contentToCopy = isStreaming ? streamingContent : relic?.content;
    if (!contentToCopy) return;
    
    try {
      await navigator.clipboard.writeText(contentToCopy);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  // Handle close with unpinning
  const handleClose = () => {
    onClose(); // Just call onClose - the container will handle unpinning
  };
  

  const handleDelete = async (): Promise<void> => {
    if (!relic || isStreaming) return;
    try {
      onClose(); // This will trigger container cleanup
      relicStore.deleteRelic(relic.metadata.id);
      } catch (err) {
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>{`Failed to delete relic: ${err}`}</AlertDescription>
        </Alert>
        console.error('Failed to delete relic:', err);
      }
  };
  

  const handleReset = () => {
    const newDimensions = calculatePanelDimensions(window.innerWidth);
    const newPosition = calculateInitialPosition(
      window.innerWidth,
      window.innerHeight,
      newDimensions.width,
      newDimensions.height
    );
    
    setDimensions(newDimensions);
    setPosition(newPosition);
    
    // Clear saved position from localStorage
    localStorage.removeItem(STORAGE_KEY);
    
    // Show brief animation on the refresh icon
    const button = document.getElementById('reset-position-button');
    if (button) {
      button.classList.add('animate-spin');
      setTimeout(() => {
        button.classList.remove('animate-spin');
      }, 500);
    }
  };

  if (!isOpen) return null;

  const displayContent = isStreaming ? streamingContent : relic?.content || '';

  return (
    <>
      <div
        ref={panelRef}
        style={isMaximized ? {
          position: 'fixed',
          inset: '1rem',
          width: 'auto',
          height: 'auto',
          transform: 'none'
        } : {
          position: 'fixed',
          left: 0,
          top: 0,
          width: `${dimensions.width}px`,
          height: `${dimensions.height}px`,
          transform: `translate(${position.x}px, ${position.y}px)`,
          cursor: isDragging ? 'grabbing' : undefined
        }}
        className={`bg-zinc-900 border border-zinc-700 rounded-lg shadow-2xl 
          flex flex-col transition-all duration-200 relative
          ${isMaximized ? 'transition-[inset] z-20' : 'transition-transform z-15'}
          ${window.innerWidth < 640 ? 'w-[calc(100vw-2rem)] mx-4' : ''}`}
      >

        {/* Resize handles - only show when not maximized */}
        {!isMaximized && (
          <>
            {/* Corner handles */}
            <div
              className="absolute top-0 left-0 w-4 h-4 cursor-nw-resize"
              onMouseDown={(e) => handleResizeStart(e, 'nw')}
            />
            <div
              className="absolute top-0 right-0 w-4 h-4 cursor-ne-resize"
              onMouseDown={(e) => handleResizeStart(e, 'ne')}
            />
            <div
              className="absolute bottom-0 left-0 w-4 h-4 cursor-sw-resize"
              onMouseDown={(e) => handleResizeStart(e, 'sw')}
            />
            <div
              className="absolute bottom-0 right-0 w-4 h-4 cursor-se-resize"
              onMouseDown={(e) => handleResizeStart(e, 'se')}
            />

            {/* Edge handles */}
            <div
              className="absolute top-0 left-4 right-4 h-1 cursor-ns-resize"
              onMouseDown={(e) => handleResizeStart(e, 'n')}
            />
            <div
              className="absolute bottom-0 left-4 right-4 h-1 cursor-ns-resize"
              onMouseDown={(e) => handleResizeStart(e, 's')}
            />
            <div
              className="absolute left-0 top-4 bottom-4 w-1 cursor-ew-resize"
              onMouseDown={(e) => handleResizeStart(e, 'w')}
            />
            <div
              className="absolute right-0 top-4 bottom-4 w-1 cursor-ew-resize"
              onMouseDown={(e) => handleResizeStart(e, 'e')}
            />
          </>
        )}

        {/* Show resize overlay when resizing */}
        {isResizing && (
          <div className="absolute inset-0 bg-blue-500/10 pointer-events-none" />
        )}

        {/* Header */}
        <div 
          className={`flex items-center justify-between p-4 border-b border-zinc-800
                     ${!isMaximized ? 'cursor-grab active:cursor-grabbing' : ''}
                     ${window.innerWidth < 640 ? 'p-2' : 'p-4'}`}
          onMouseDown={!isMaximized ? handleMouseDown : undefined}
        >
          <div className="flex items-center gap-3">
            <Boxes className={`
              ${isStreaming ? 'text-blue-400 animate-pulse' : 'text-zinc-400'}
              ${error ? 'text-red-400' : ''}
              ${window.innerWidth < 640 ? 'w-4 h-4' : 'w-5 h-5'}
            `} />
            <div className="flex items-center gap-2">
              <h2 className={`font-semibold text-white
                ${window.innerWidth < 640 ? 'text-sm' : 'text-lg'}`}>
                {relic?.metadata.title || 'Generating Relic...'}
              </h2>
              {isStreaming && (
                <div className="flex items-center gap-2 text-blue-400">
                  <Loader2 className="w-4 h-4 animate-spin" />
                  <span className={`${window.innerWidth < 640 ? 'text-xs' : 'text-sm'}`}>
                    Generating...
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className="flex items-center gap-2">
            {/* Pin Toggle Button */}
            <button
              onClick={(e) => {
                e.stopPropagation();
                onPinToggle();
              }}
              className={`p-1.5 rounded-md transition-colors
                ${isPinned 
                  ? 'text-blue-400 hover:text-blue-300 bg-blue-500/10' 
                  : 'text-zinc-400 hover:text-zinc-300 hover:bg-zinc-800'}`}
              title={isPinned ? 'Unpin relic' : 'Pin relic'}
            >
              <Pin size={16} className={isPinned ? 'rotate-45' : ''} />
            </button>
            
          <button
            onClick={() => setShowDeleteConfirm(true)}
            className="p-1.5 text-zinc-400 hover:text-red-400 transition-colors rounded-md hover:bg-zinc-800"
            title="Delete relic"
          >
            <Trash2 size={16} />
          </button>
            <button
              id="reset-position-button"
              onClick={handleReset}
              disabled={isMaximized}
              className="p-1.5 text-zinc-400 hover:text-zinc-300 transition-colors 
                        rounded-md hover:bg-zinc-800 disabled:opacity-50
                        disabled:cursor-not-allowed"
              title="Reset position and size"
            >
              <RefreshCw size={16} />
            </button>
            <button
              onClick={handleCopy}
              disabled={!displayContent || isStreaming}
              className="p-1.5 text-zinc-400 hover:text-zinc-300 transition-colors 
                        rounded-md hover:bg-zinc-800 disabled:opacity-50
                        disabled:cursor-not-allowed"
            >
              {copied ? <Check size={16} /> : <Copy size={16} />}
            </button>
            <button
              onClick={handleMaximize}
              className="p-1.5 text-zinc-400 hover:text-zinc-300 transition-colors
                        rounded-md hover:bg-zinc-800"
            >
              {isMaximized ? <Minimize2 size={16} /> : <Maximize2 size={16} />}
            </button>
            <button
              onClick={handleClose}
              className="p-1.5 text-zinc-400 hover:text-zinc-300 transition-colors
                        rounded-md hover:bg-zinc-800"
            >
              <X size={16} />
            </button>
          </div>
        </div>

        {/* Content */}
        <div 
          ref={contentRef}
          className={`flex-1 overflow-y-auto ${window.innerWidth < 640 ? 'p-3' : 'p-6'}`}
        >
          {error ? (
            <Alert variant="destructive">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          ) : displayContent ? (
            <div className="relative rounded-lg overflow-hidden">
              <SafeMarkdown content={displayContent} />
            </div>
          ) : (
            <div className="animate-pulse space-y-2">
              <div className="h-4 bg-zinc-800 rounded w-3/4"></div>
              <div className="h-4 bg-zinc-800 rounded w-1/2"></div>
            </div>
          )}
        </div>

        {/* Streaming progress bar */}
        {isStreaming && !error && (
          <div className="absolute bottom-0 left-0 right-0 h-1">
            <div className="h-full bg-blue-400/20 relative overflow-hidden">
              <div className="absolute inset-0 translate-x-[-100%] 
                            animate-[stream_1.5s_ease-in-out_infinite] 
                            bg-blue-400/40" />
            </div>
          </div>
        )}
      </div>

      {/* Add the confirmation dialog */}
      <ConfirmDialog
        isOpen={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        onConfirm={handleDelete}
        title="Delete Relic"
        message={`Are you sure you want to delete this relic? This action cannot be undone.`}
      />
    </>
  );
};