import { Info } from 'lucide-react';
import { Project, ProjectContextConfig } from '../../types/project';

interface ProjectContextSettingsProps {
  project: Project;
  onChange: (config: ProjectContextConfig) => void;
}

export default function ProjectContextSettings({ project, onChange }: ProjectContextSettingsProps) {
    const handleConfigChange = (changes: Partial<ProjectContextConfig>) => {
        const newConfig = {
          ...project.contextConfig,
          ...changes
        };
      
        // If toggling RAG or Cache off, update strategy accordingly
        if ('useRag' in changes || 'usePromptCache' in changes) {
          if (newConfig.useRag && !newConfig.usePromptCache) {
            newConfig.strategy = 'rag-only';
          } else if (!newConfig.useRag && newConfig.usePromptCache) {
            newConfig.strategy = 'cache-only';
          } else if (newConfig.useRag && newConfig.usePromptCache) {
            newConfig.strategy = 'hybrid'; // Default to hybrid when both enabled
          }
        }
      
        onChange(newConfig);
      };

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-2 mb-4">
        <h3 className="text-lg font-medium text-white">Context Settings</h3>
        <div className="group relative">
          <Info className="w-4 h-4 text-zinc-400 hover:text-zinc-300" />
          <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 w-64 p-2 
                       bg-zinc-800 text-xs text-zinc-300 rounded-lg opacity-0 
                       group-hover:opacity-100 transition-opacity pointer-events-none">
            Configure how project context is provided during conversations
          </div>
        </div>
      </div>

      {/* RAG Toggle */}
      <div className="flex items-center justify-between p-4 rounded-lg border border-zinc-700 bg-zinc-800/50">
        <div>
          <div className="font-medium text-white">RAG Search</div>
          <div className="text-sm text-zinc-400">Use vector search for relevant code snippets</div>
        </div>
        <label className="relative inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            checked={project.contextConfig.useRag}
            onChange={(e) => handleConfigChange({ useRag: e.target.checked })}
            className="sr-only peer"
          />
          <div className="w-11 h-6 bg-zinc-700 rounded-full peer 
                       peer-checked:after:translate-x-full peer-checked:bg-blue-600
                       after:content-[''] after:absolute after:top-[2px] 
                       after:left-[2px] after:bg-white after:rounded-full 
                       after:h-5 after:w-5 after:transition-all">
          </div>
        </label>
      </div>

      {/* Prompt Cache Toggle */}
      <div className="flex items-center justify-between p-4 rounded-lg border border-zinc-700 bg-zinc-800/50">
        <div>
          <div className="font-medium text-white">Prompt Cache</div>
          <div className="text-sm text-zinc-400">Cache project context for faster responses</div>
        </div>
        <label className="relative inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            checked={project.contextConfig.usePromptCache}
            onChange={(e) => handleConfigChange({ usePromptCache: e.target.checked })}
            className="sr-only peer"
          />
          <div className="w-11 h-6 bg-zinc-700 rounded-full peer 
                       peer-checked:after:translate-x-full peer-checked:bg-blue-600
                       after:content-[''] after:absolute after:top-[2px] 
                       after:left-[2px] after:bg-white after:rounded-full 
                       after:h-5 after:w-5 after:transition-all">
          </div>
        </label>
      </div>

      {/* Strategy Selection - Only show if both are enabled */}
      {(project.contextConfig.useRag || project.contextConfig.usePromptCache) && (
        <div className="mt-4">
            <div className="font-medium text-white mb-2">Context Strategy</div>
            <div className="grid grid-cols-3 gap-2">
            <button
                disabled={!project.contextConfig.useRag}
                onClick={() => handleConfigChange({ strategy: 'rag-only' })}
                className={`p-2 rounded-lg border text-sm transition-all ${
                    project.contextConfig.strategy === 'rag-only'
                      ? 'border-blue-500 bg-blue-500/10 text-blue-400'
                      : 'border-zinc-700 hover:border-zinc-600 text-zinc-400 hover:text-zinc-300'
                  }`}>
                RAG Only
            </button>
            <button
                disabled={!project.contextConfig.usePromptCache}
                onClick={() => handleConfigChange({ strategy: 'cache-only' })}
                className={`p-2 rounded-lg border text-sm transition-all ${
                    project.contextConfig.strategy === 'cache-only'
                      ? 'border-blue-500 bg-blue-500/10 text-blue-400'
                      : 'border-zinc-700 hover:border-zinc-600 text-zinc-400 hover:text-zinc-300'
                  }`}>
                Cache Only
            </button>
            <button
                disabled={!project.contextConfig.useRag || !project.contextConfig.usePromptCache}
                onClick={() => handleConfigChange({ strategy: 'hybrid' })}
                className={`p-2 rounded-lg border text-sm transition-all ${
                  project.contextConfig.strategy === 'hybrid'
                    ? 'border-blue-500 bg-blue-500/10 text-blue-400'
                    : 'border-zinc-700 hover:border-zinc-600 text-zinc-400 hover:text-zinc-300'
                }`}>
                Combined
            </button>
          </div>
        </div>
      )}
    </div>
  );
}