import React from 'react';
import { ArrowDown } from 'lucide-react';

interface ScrollToBottomButtonProps {
  show: boolean;
  onClick: () => void;
}

const ScrollToBottomButton: React.FC<ScrollToBottomButtonProps> = ({ show, onClick }) => {
  if (!show) return null;

  return (
    <button
      onClick={onClick}
      className="fixed left-1/2 bottom-[9rem] transform -translate-x-1/2 
                 bg-zinc-800 hover:bg-zinc-700 p-1 rounded-full shadow-lg 
                 transition-all duration-200 animate-fade-in z-20 group"
      aria-label="Scroll to bottom"
    >
      <div className="relative">
        <ArrowDown className="text-zinc-300 group-hover:text-white transition-colors" size={16} />
        
        {/* Tooltip */}
        <div className="absolute bottom-[1.5rem] left-[-2.6rem]
                        px-2 py-1 bg-zinc-900 text-zinc-300 text-xs rounded 
                        whitespace-nowrap opacity-0 group-hover:opacity-100 
                        transition-opacity pointer-events-none">
          Scroll to bottom
        </div>
      </div>
    </button>
  );
};

export default React.memo(ScrollToBottomButton);
