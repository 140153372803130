// ConfigIndicator.tsx
import React, { useState } from 'react';
import { Settings2 } from 'lucide-react';

interface ConfigIndicatorProps {
  onClick: () => void;
}

const ConfigIndicator: React.FC<ConfigIndicatorProps> = ({ onClick }) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);

  return (
    <button
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`text-zinc-400 hover:text-white transition-all duration-300 ${
        isHovering ? 'wiggle-hover tilt-right' : ''
      }`}
      aria-label="Open configuration"
    >
      <Settings2 size={20} />
    </button>
  );
};

export default ConfigIndicator;
