import React, { useState, useEffect, useCallback } from 'react';
import { Boxes, Pin, Plus } from 'lucide-react';
import { Relic } from '../../types/relic';
import { relicStore } from '../../services/relicStore';

interface RelicIndicatorProps {
  threadRelics: Relic[];
  onRelicSelect: (relicId: string) => void;
  onRelicPanelOpen?: () => void;
}

export const RelicIndicator: React.FC<RelicIndicatorProps> = ({
  threadRelics,
  onRelicSelect,
  onRelicPanelOpen
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [showBadge, setShowBadge] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [currentRelics, setCurrentRelics] = useState<Relic[]>(threadRelics);

  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);

  // Update local state when threadRelics prop changes
  useEffect(() => {
    setCurrentRelics(threadRelics);
  }, [threadRelics]);

  // Enhanced event handler for relic state changes
  const handleRelicStateChange = useCallback(() => {
    // Force immediate re-render with latest relics
    setCurrentRelics(prevRelics => {
      const updatedRelics = prevRelics.filter(relic => {
        const stillExists = relicStore.getRelic(relic.metadata.id);
        return !!stillExists;
      });
      return updatedRelics;
    });
  }, []);

  // Set up event listeners for all relic-related events
  useEffect(() => {
    const events = [
      'relic-pin-changed',
      'relic-deleted',
      'relics-updated'
    ];

    events.forEach(eventName => {
      window.addEventListener(eventName, handleRelicStateChange);
    });

    return () => {
      events.forEach(eventName => {
        window.removeEventListener(eventName, handleRelicStateChange);
      });
    };
  }, [handleRelicStateChange]);

  useEffect(() => {
    const handleRelicCreated = () => {
      setIsAnimating(true);
      setShowBadge(true);
      setTimeout(() => setIsAnimating(false), 1000);
    };

    window.addEventListener('relic-generation-complete', handleRelicCreated);
    return () => {
      window.removeEventListener('relic-generation-complete', handleRelicCreated);
    };
  }, []);

  const handleClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setShowBadge(false);
  };

  const handlePreviewClick = () => {
    handleMouseLeave();
    setIsDropdownOpen(false);
  };

  const handleOutsideClick = () => {
    setIsDropdownOpen(false);
  };

  // Validate relics before rendering
  const validRelics = currentRelics.filter(relic => {
    const exists = relicStore.getRelic(relic.metadata.id);
    return !!exists;
  });

  return (
    <div className="relative group mt-1"
         onMouseEnter={handleMouseEnter}
         onMouseLeave={handleMouseLeave}>
      <button
        onClick={handleClick}
        className={`relative transition-all ${
          isHovering ? 'wiggle-hover tilt-right' : ''
        }`}
        aria-label="View relics"
      >
        <Boxes
          className={`w-5 h-5 text-zinc-400 group-hover:text-zinc-300 transition-colors
                     ${isAnimating ? 'animate-wiggle' : ''}`}
        />
        {showBadge && (
          <Plus className="absolute -top-1 -right-1 w-3 h-3 bg-blue-500 rounded-full 
                          text-white animate-fade-in" />
        )}
      </button>

      {validRelics.length > 0 && !showBadge && (
        <div className="absolute -top-2 -right-2 bg-blue-500 text-white text-xs 
                       rounded-full w-4 h-4 flex items-center justify-center">
          {validRelics.length}
        </div>
      )}

      {isDropdownOpen && (
        <>
          <div 
            className="fixed inset-0 z-10" 
            onClick={handleOutsideClick}
          />
          <div className="absolute top-full left-1/2 -translate-x-1/2 mt-2 w-96
                         bg-zinc-800 border border-zinc-700 rounded-lg shadow-lg z-20
                         max-h-[70vh] overflow-y-auto">
            <div className="p-4 space-y-2">
              <h3 className="text-sm font-medium text-zinc-300 mb-3">
                Thread Relics
              </h3>
              {validRelics.length > 0 ? (
                <div className="space-y-2">
                  {validRelics.map((relic) => (
                    <button
                      key={relic.metadata.id}
                      onClick={() => {
                        onRelicSelect(relic.metadata.id);
                        handlePreviewClick();
                      }}
                      className="w-full text-left p-3 hover:bg-zinc-700/50 rounded-md
                                flex items-center gap-3 group/item transition-colors"
                    >
                      <Boxes className="w-5 h-5 text-blue-400 shrink-0" />
                      <div className="flex-1 min-w-0">
                        <div className="font-medium text-white truncate">
                          {relic.metadata.title}
                        </div>
                        <div className="text-xs text-zinc-400 flex items-center gap-2">
                          <span>{relic.metadata.type}</span>
                          {relic.metadata.language && (
                            <>
                              <span className="text-zinc-600">•</span>
                              <span>{relic.metadata.language}</span>
                            </>
                          )}
                          {relicStore.isPinned(relic.metadata.id) && (
                            <Pin size={12} className="text-blue-400 rotate-45" />
                          )}
                        </div>
                      </div>
                    </button>
                  ))}
                </div>
              ) : (
                <div className="text-sm text-zinc-400 text-center py-6">
                  No relics created yet
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};