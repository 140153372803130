// components/chat/MessageContainer.tsx
import React, { useRef, useEffect, useState } from 'react';
import MessageContent from './MessageContent';
import { StreamingMessage } from './StreamingMessage';
import { Model } from './ModelSelector';
import { Message } from '../../types/types';
import { Relic } from '../../types/relic';
import { relicStore } from '../../services/relicStore';

interface MessageContainerProps {
  messages: Message[];
  streamingContent: string;
  isLoading: boolean;
  currentModel?: Model;
  onScroll: () => void;
  shouldAutoScroll: boolean;
  isRelicOpen: boolean;  // Add these
  onRelicSelect: (relicId: string) => void;  // Add these
}

export const MessageContainer: React.FC<MessageContainerProps> = ({
  messages,
  streamingContent,
  isLoading,
  currentModel,
  onScroll,
  shouldAutoScroll,
  isRelicOpen,
  onRelicSelect,
}) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const previousMessagesLength = useRef(messages.length);
  const [hasPinnedRelics, setHasPinnedRelics] = useState(false);

  // Handle auto-scrolling
  useEffect(() => {
    if (shouldAutoScroll && messagesEndRef.current) {
      const shouldSmooth = messages.length === previousMessagesLength.current;
      messagesEndRef.current.scrollIntoView({ 
        behavior: shouldSmooth ? 'smooth' : 'auto' 
      });
    }
    previousMessagesLength.current = messages.length;
  }, [messages, streamingContent, shouldAutoScroll]);

  useEffect(() => {
    const checkPinnedRelics = () => {
      // This assumes relicStore has a method to check for pinned relics
      const anyPinned = relicStore.hasAnyPinnedRelics();
      setHasPinnedRelics(anyPinned);
    };

    checkPinnedRelics();
    window.addEventListener('relic-pin-changed', checkPinnedRelics);
    return () => window.removeEventListener('relic-pin-changed', checkPinnedRelics);
  }, []);

  // Calculate dynamic classes for message container
  const getMessageContainerClasses = (isRelicOpen: boolean) => [
    'flex-1 overflow-y-auto transition-all duration-300 ease-in-out',
    'px-4 md:px-8 py-4',
    (isRelicOpen || hasPinnedRelics) ? 'mr-[800px]' : ''
  ].filter(Boolean).join(' ');

  // Calculate dynamic classes for message content wrapper
  const getMessageContentClasses = (isRelicOpen: boolean) => [
    'max-w-4xl mx-auto space-y-4',
    'transition-all duration-300 ease-in-out',
    (isRelicOpen || hasPinnedRelics) ? 'max-w-2xl' : ''
  ].filter(Boolean).join(' ');

  return (
    <>
      <div 
        ref={containerRef}
        className={getMessageContainerClasses(isRelicOpen)}
        onScroll={onScroll}
      >
        <div className={getMessageContentClasses(isRelicOpen)}>
          {/* Render messages */}
          {messages.map((message) => (
            <div 
              key={message.id} 
              className="transform transition-all duration-300 ease-in-out"
            >
              {/* Message content */}
              <MessageContent 
                message={message}
                onRelicClick={onRelicSelect}
              />
            </div>
          ))}
          
          {/* Streaming message */}
          {streamingContent && !isLoading && (
            <StreamingMessage 
              content={streamingContent}
              isComplete={false}
              model={currentModel}
            />
          )}
          
          {/* Scroll anchor */}
          <div ref={messagesEndRef} />
        </div>
      </div>
    </>
  );
};