import { useState, useCallback } from "react";
import { ragService } from "../services/RAGService";

// hooks/useRAGContext.ts
export const useRAGContext = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const getRelevantContext = useCallback(async (query: string): Promise<string> => {
    try {
      setIsLoading(true);
      setError(null);
      return await ragService.getRelevantContext(query);
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to get context');
      return '';
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    getRelevantContext,
    isLoading,
    error
  };
};